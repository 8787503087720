import "core-js/modules/es.array.push.js";
import PublicFooter from '@/components/PublicFooter.vue';
import BPublicHeader from '@/components/BPublicHeader.vue';
import { getIbJoinInfoAdd, getexportPdf } from '@/api/index';
export default {
  name: 'ConfirmData',
  components: {
    BPublicHeader,
    PublicFooter
  },
  data() {
    return {
      address: '',
      list: [],
      DataInfo: [],
      NumberArr: {
        RegisteredCapital: 'float',
        JobPeopleCount: 'int',
        SDPhone: 'int',
        D1Phone: 'int',
        D2Phone: 'int',
        Industry: 'jsonstring'
      },
      fileList: [],
      fileUrl: '',
      BranName: ''
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
    this.dataRule();
  },
  methods: {
    addImgsuccess(response, file, fileList) {
      this.fileUrl = response.Data;
    },
    handleRemove() {
      this.fileUrl = '';
    },
    handlePreview(uploadFile) {},
    dataRule() {
      let applyInfopdf = JSON.parse(localStorage.getItem('applyInfo'));
      applyInfopdf.typeName = '常务理事';
      applyInfopdf.completePDF = applyInfopdf.examinePDF = 'string';
      applyInfopdf.isExamine = 0,
      // applyInfopdf.groupName = 'string'
      // applyInfopdf.acNmae = 'string'
      applyInfopdf.createTime = '2022-09-05';
      applyInfopdf.createUser = 'string';
      applyInfopdf.isDel = 0;
      applyInfopdf.pageIndex = 0;
      applyInfopdf.pageSize = 0;
      let NumberArr = this.NumberArr;
      for (var i in NumberArr) {
        if (NumberArr[i] == 'int') {
          applyInfopdf[i] = parseInt(applyInfopdf[i]);
        } else if (NumberArr[i] == 'float') {
          applyInfopdf[i] = parseFloat(applyInfopdf[i]);
        } else if (NumberArr[i] == 'jsonstring') {
          applyInfopdf[i] = applyInfopdf[i].join(',');
        }
      }
      this.DataInfo = applyInfopdf;
      // console.log(this.DataInfo)
    },
    // 导出pdf
    postData() {
      if (this.DataInfo.D2Phone == '') {
        this.DataInfo.D2Phone = 0;
      }
      getexportPdf(this.DataInfo).then(res => {
        this.fileList = res.data;
        // console.log(this.fileList)
        window.open(res);
        // return applyInfopdf
        // console.log(applyInfopdf)
      });
    },
    // 上传数据
    nextSubmit() {
      let addData = this.DataInfo;
      addData.completePDF = this.fileUrl;
      if (this.fileUrl == '') {
        //请上传pdf
        alert('请上传上传格式为PDF扫描文件');
        return;
      }
      getIbJoinInfoAdd(addData).then(res => {
        // console.log(res)
        if (res.Code == 1) {
          //销毁、下一步操作
          localStorage.clear();
          this.$router.push('/ApplyFinish?BranName=' + this.BranName);
        }
        //  localStorage.getItem('applyInfo')
      });
    }
  }
};