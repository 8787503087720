import "core-js/modules/es.array.push.js";
import PublicHeader from '@/components/PublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import BannerEight from '@/components/banner/BannerEight.vue';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
// Import Swiper Vue.js components
import '../../../node_modules/swiper/swiper-bundle.css';
import { ref } from 'vue';
import { Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { getSysGuestsHomeList, getSysKeynoteHomeList, getIbActivityHomeDetailList } from '@/api/index.js';
export default {
  name: 'topic',
  components: {
    PublicHeader,
    PublicFooter,
    BannerEight,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      // 嘉宾风采
      Guest: [],
      // 主题发言
      speech: [],
      // 活动概况
      list: [],
      //顶部轮播
      swiperTop: [],
      // 底部轮播
      swiperBottom: [],
      // 活动查询
      HDCX: [],
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay, Thumbs],
      metaname: ''
    };
  },
  setup() {
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = swiper => {
      thumbsSwiper.value = swiper;
    };
    return {
      Thumbs,
      thumbsSwiper,
      setThumbsSwiper
    };
  },
  mounted() {
    this.Id = this.$route.query.Id;
    // this.columnName = this.$route.query.columnName
    // this.branchName = this.$route.query.branchName
    // this.ActiNote = this.$route.query.ActiNote
    // 嘉宾风采
    this.getSysGuests();
    // 主题发言
    this.getSysKeynote();
    // 活动概况 会场掠影 底部轮播
    // this.getIbActivity()
    // 活动查询
    this.getIbActivityHomeDetail();
  },
  methods: {
    getSysGuests() {
      this.Guest = [];
      getSysGuestsHomeList({
        acId: this.Id
      }).then(res => {
        this.Guest = res;
        // console.log(this.Guest)
      });
    },
    getSysKeynote() {
      this.speech = [];
      getSysKeynoteHomeList({
        acId: this.Id
      }).then(res => {
        this.speech = res;
        // console.log(this.speech)
      });
    },
    getIbActivityHomeDetail() {
      this.HDCX = [];
      this.swiperTop = [];
      this.LueYing = [];
      getIbActivityHomeDetailList({
        id: this.Id
      }).then(res => {
        this.HDCX = res;
        this.metaname = res[0].ActiTitle;
        // console.log(this.HDCX)
        let data = [];
        let data1 = [];
        let i = 1;
        res[0].BannerUrl.forEach(element => {
          if (i > 1) {
            data.push(element);
          }
          i++;
        });
        this.swiperTop = data;
        // console.log(this.swiperTop)
        res[0].VenuePngUrl.forEach(element => {
          data1.push(element);
        });
        this.swiperBottom = data1;
        // console.log(this.LueYing, '+++++lueying')
      });
    }
    // 活动概况 会场掠影 底部轮播
    // getIbActivity() {
    //   ;(this.list = []),
    //     (this.swiperTop = []),
    //     (this.LueYing = []),
    //     getIbActivityHomeList({ pageIndex: 1, pageSize: 16, Id: this.Id, columnName: this.columnName, branchName: this.branchName }).then(res => {
    //       this.list = res
    //       let data = []
    //       let data1 = []
    //       let i = 1
    //       res[0].BannerUrl.forEach(element => {
    //         if (i > 1) {
    //           data.push(element)
    //         }
    //         i++
    //       })
    //       this.swiperTop = data
    //       console.log(this.swiperTop)
    //       res[0].VenuePngUrl.forEach(element => {
    //         data1.push(element)
    //       })
    //       this.swiperBottom = data1
    //       console.log(this.swiperBottom)
    //     })
    // }
  }
};