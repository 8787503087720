import BPublicHeader from '@/components/BPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import { getIbBranchesHomeList } from '@/api/index.js';
export default {
  name: 'apply',
  components: {
    BPublicHeader,
    PublicFooter
  },
  data() {
    return {
      list: [{
        id: 1,
        name: '理事单位申请',
        url: '/apply/applyfirst'
      }, {
        id: 2,
        name: '常务理事单位申请',
        url: '/apply/applysecond'
      }, {
        id: 3,
        name: '变更或其他',
        url: '/apply/change'
      }, {
        id: 4,
        name: '电子证书下载',
        url: '/apply/certificates'
      }],
      list2: [{
        id: 1,
        name: '委员单位申请',
        url: '/apply/applyfirst'
      }, {
        id: 2,
        name: '常务委员单位申请',
        url: '/apply/applysecond'
      }, {
        id: 3,
        name: '变更或其他',
        url: '/apply/change'
      }, {
        id: 4,
        name: '电子证书下载',
        url: '/apply/certificates'
      }],
      BranName: '',
      activeNum: 0,
      listRY: []
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
    this.getIbBranches();
  },
  methods: {
    active(i) {
      this.activeNum = i;
    },
    // 荣誉照片
    getIbBranches() {
      this.listRY = [];
      getIbBranchesHomeList({
        pageIndex: 1,
        pageSize: 50,
        BranName: this.BranName
      }).then(res => {
        this.listRY = res[0].branHonorUrls;
        // console.log(this.listRY)
      });
    }
  }
};