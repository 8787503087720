import PublicHeader from '@/components/PublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import indexSwiper from '@/components/indexSwiper.vue';
import { getIbPngContentHomeList } from '@/api/index.js';
export default {
  emits: ['searchOc'],
  name: 'ResearchReport',
  components: {
    PublicHeader,
    PublicFooter,
    indexSwiper
  },
  data() {
    return {
      list: [],
      // 研究报告
      listYJ: [],
      page: {
        Index: 1,
        Size: 5,
        Total: 50
      },
      ArticleTitel: '',
      alert: '',
      img: ''
    };
  },
  mounted() {
    this.ArticleTitel = this.$route.query.ArticleTitel;
    this.getIbPngContent();
  },
  methods: {
    search(e) {
      // console.log(e, '++++++++++++++++++222222')
      this.ArticleTitel = e;
      this.getIbPngContent();
    },
    getIbPngContent() {
      this.listYJ = [];
      getIbPngContentHomeList({
        pageIndex: this.page.Index,
        pageSize: this.page.Size,
        PlateName: '研究报告',
        ArticleTitel: this.ArticleTitel
      }).then(res => {
        this.listYJ = res.rows;
        this.page.Total = res.total;
        if (res.rows == '') {
          // alert('没有搜索到具体内容')
          ;
          this.alert = '没有搜索到具体内容,请重新输入有效信息', this.img = '	https://gw.alicdn.com/imgextra/i3/O1CN01DKaORe1dcIHH0eMRx_!!6000000003756-55-tps-164-142.svg';
        }
      });
    },
    handleCurrentChange(val) {
      this.page.Index = val;
      // console.log(`当前页: ${val}`)
      this.getIbPngContent();
    }
  },
  watch: {
    $route(to, from) {
      window.location.reload(); //监测到路由发生跳转时刷新一次页面
    }
  }
};