import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "flex w-875 wrap align-content-start between duiqi"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dataList, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "center",
      key: item.Id,
      style: {
        "margin-bottom": "20px"
      }
    }, [_createElementVNode("a", {
      href: item.ToUrl,
      style: {
        "display": "block"
      }
    }, [_createElementVNode("img", {
      class: "item-img",
      src: item.PicUrl
    }, null, 8, _hoisted_3)], 8, _hoisted_2)]);
  }), 128))]);
}