import CPublicHeader from '@/components/CPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import { WebSiteGetFuWuZhanshiData, WebSiteGetAwardstableData } from '@/api/index';
export default {
  name: 'Award',
  components: {
    CPublicHeader,
    PublicFooter
  },
  data() {
    return {
      list: [{
        id: 1,
        name: '竞争力十强品牌',
        path: '/Award/topten'
      }, {
        id: 2,
        name: '设计院渠道优秀工作者',
        path: '/Award/worker'
      }, {
        id: 3,
        name: '建筑电气创新产品',
        path: '/Award/newproduct'
      }],
      photoList: [],
      //服务展示
      textList: [],
      //申报条件
      activeTab: '竞争力十强品牌' //点击切换项
    };
  },
  mounted() {
    this.getPhoto(); //获取服务展示
    this.getTextList(); //获取申报条件
  },
  methods: {
    //点击切换项
    active(i) {
      this.activeTab = i;
      this.getTextList(i);
    },
    //获取服务展示
    getPhoto() {
      this.photoList = [];
      WebSiteGetFuWuZhanshiData().then(res => {
        this.photoList = res.Data;
      });
    },
    //获取申报条件
    getTextList(i) {
      this.textList = [];
      WebSiteGetAwardstableData({
        typename: i
      }).then(res => {
        this.textList == res.Data;
      });
    }
  }
};