// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import '../../../node_modules/swiper/swiper-bundle.css';
import { getIbActivityHomeList } from '../../api/index.js';
export default {
  name: 'BannerNinth',
  props: {
    list: {
      type: Array
    },
    imgnum: {
      type: Number
    },
    imgh: {
      type: Number
    },
    imgw: {
      type: Number
    },
    clickable: {
      type: Boolean
    },
    text: {
      type: String
    },
    address: {
      type: String
    },
    imgJG: {
      type: Number
    },
    CoverPngUrl: {
      type: String
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      // A01活动专题
      Active: [],
      BranName: ''
    };
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay]
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
    this.getIbActivity();
  },
  methods: {
    // A01活动专题
    getIbActivity() {
      this.Active = [];
      getIbActivityHomeList({
        pageIndex: 1,
        pageSize: 16
      }).then(res => {
        this.Active = res;
        this.$emit('bannerListHD', this.Active);
        // console.log(this.Active)
      });
    }
  }
};