import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1b1dcf9c"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["href"];
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "w-100 center font-9 font-400 color-0 two-nowrap"
};
const _hoisted_4 = {
  class: "flex column between"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "center font-9 font-400 color-0 two-nowrap w-190"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_swiper_slide = _resolveComponent("swiper-slide");
  const _component_swiper = _resolveComponent("swiper");
  return _openBlock(), _createBlock(_component_swiper, {
    modules: $setup.modules,
    "slides-per-view": $props.imgnum,
    "space-between": $props.imgJG,
    navigation: "",
    "slides-Per-Group": $props.GroupNum
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, item => {
      return _openBlock(), _createBlock(_component_swiper_slide, {
        key: item.id,
        class: "flex column"
      }, {
        default: _withCtx(() => [item.ExternalUrl !== '' ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          target: "_blank",
          class: "flex column between",
          to: {
            name: 'BranchImageText',
            query: {
              Id: item.Id,
              BranName: this.BranName
            }
          }
        }, {
          default: _withCtx(() => [_createElementVNode("a", {
            href: item.ExternalUrl,
            target: "_blank",
            class: "color-0 center"
          }, [_createElementVNode("img", {
            src: item.CoverPngUrl,
            alt: "",
            style: _normalizeStyle({
              'height': $props.imgh + 'px',
              'width': $props.imgw + 'px'
            })
          }, null, 12, _hoisted_2), _createElementVNode("em", _hoisted_3, _toDisplayString(item.ActiNote) + _toDisplayString(item.ArticleTitel) + _toDisplayString(item.VideoTitle), 1)], 8, _hoisted_1)]),
          _: 2
        }, 1032, ["to"])) : (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: {
            name: 'BranchImageText',
            query: {
              Id: item.Id,
              BranName: this.BranName
            }
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
            src: item.CoverPngUrl,
            alt: "",
            style: _normalizeStyle({
              'height': $props.imgh + 'px',
              'width': $props.imgw + 'px'
            })
          }, null, 12, _hoisted_5), _createElementVNode("em", _hoisted_6, _toDisplayString(item.ActiNote) + _toDisplayString(item.ArticleTitel) + _toDisplayString(item.VideoTitle), 1)])]),
          _: 2
        }, 1032, ["to"]))]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }, 8, ["modules", "slides-per-view", "space-between", "slides-Per-Group"]);
}