import { GetUsBsan } from '@/api/index.js';
import CPublicHeader from '@/components/CPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
export default {
  name: 'ContactUs',
  components: {
    CPublicHeader,
    PublicFooter
  },
  data() {
    return {
      list: [] //联系我们列表
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    getlist() {
      this.list = [];
      GetUsBsan().then(res => {
        this.list = res.Data;
      });
    }
  }
};