import PublicHeader from '@/components/PublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import indexSwiper from '@/components/indexSwiper.vue';
import BannerFirst from '@/components/banner/BannerFirst.vue';
import { getIbPngContentHomeList } from '@/api/index.js';
export default {
  name: 'ResearchDetail',
  components: {
    PublicHeader,
    PublicFooter,
    BannerFirst,
    indexSwiper
  },
  data() {
    return {
      listYJ: [],
      Id: '',
      PlateName: ''
    };
  },
  mounted() {
    this.Id = this.$route.query.Id;
    this.PlateName = this.$route.query.PlateName;
    this.getIbPngContent();
  },
  methods: {
    getIbPngContent() {
      this.listYJ = [];
      getIbPngContentHomeList({
        pageIndex: 1,
        pageSize: 50,
        Id: this.Id,
        PlateName: this.PlateName,
        ArticleTitel: this.ArticleTitel
      }).then(res => {
        this.listYJ = res.rows;
      });
    }
  }
};