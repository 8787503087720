export default {
  name: 'CPublicHeader',
  data() {
    return {
      tablist: [{
        id: 1,
        name: '首页',
        path: '/bsan'
      }, {
        id: 2,
        name: '组织机构',
        path: '/organize'
      }, {
        id: 3,
        name: '奖项设置',
        path: '/Award'
      }, {
        id: 4,
        name: '品牌聚焦',
        path: '/Brandfocus'
      }, {
        id: 5,
        name: '往届回顾',
        path: '/Review'
      }, {
        id: 6,
        name: '评委会',
        path: '/JudgingPanel'
      }, {
        id: 7,
        name: '联系我们',
        path: '/ContactUs'
      }]
    };
  }
};