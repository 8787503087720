import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6979b5b4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "w-875"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dataList, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "center",
      key: item.Id,
      style: {
        "margin-bottom": "20px"
      }
    }, [_createElementVNode("img", {
      class: "item-img-z",
      src: item.PicUrl
    }, null, 8, _hoisted_2)]);
  }), 128))]);
}