import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "B01-newsdetail w-875"
};
const _hoisted_2 = {
  class: "font-12 font-400 color-6 t-i lh-24 mb-37"
};
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, item => {
    return _openBlock(), _createElementBlock("p", {
      key: item.id,
      innerHTML: item.BranJob
    }, null, 8, _hoisted_3);
  }), 128))])])]);
}