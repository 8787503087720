import "core-js/modules/es.array.push.js";
import { getIbPngContentHomeList, getIbVideoContentHomeList, getbasiesMediasList, getIbBranchesHomeList } from '@/api/index';
import { ref } from 'vue';
export default {
  emits: ['searchOc'],
  name: 'PublicHeader',
  components: {},
  props: {},
  data() {
    return {
      path: this.$route.fullPath,
      // 顶部轮播
      list: [],
      list1: [],
      JuZhen: [],
      value: ref([]),
      options: [{
        value: 'notice',
        label: '通知公告'
      }, {
        value: 'news',
        label: '前沿资讯'
      }, {
        value: 'ExhibitInfo',
        label: '展会信息'
      }, {
        value: 'StarSaid',
        label: '大咖说'
      }, {
        value: 'ResearchReport',
        label: '研究报告'
      }, {
        value: 'videos',
        label: '精彩视频'
      }],
      ArticleTitel: '',
      search: '',
      selectOption: '',
      selectOptionVal: '',
      searchInfo: '',
      def: 0,
      listFenHui: []
    };
  },
  mounted() {
    this.getIbPngContent();
    this.getIbVideoContent();
    this.getbasiesMedias();

    // 分会通知
    this.getIbBranches();
    // this.value = this.options[0].value

    // this.searchSumbit()
  },
  methods: {
    selectchange(item) {
      this.selectOption = item;
      if (this.selectOption == '') {
        this.selectOption = 'notice';
      }
      // console.log(this.selectOption)
    },
    searchSumbit() {
      let splitarr = window.location.href.split('#/');
      // if (splitarr[1] == '') {
      //   // sessionStorage.setItem('not', 'notice') //这是第一次进入页面
      //   // this.selectOption = 'notice'
      // } else {
      //   this.value = sessionStorage.getItem('not')
      // }
      // console.log(this.selectOption, '__________________searchInfo')
      // this.getIbPngContent(this.selectOption, this.searchInfo)
      if (this.selectOption == 'videos') {
        this.$router.push({
          path: 'videos',
          query: {
            VideoTitle: this.searchInfo
          }
        });
        this.$emit('searchOc', this.searchInfo);
        return;
      }
      sessionStorage.setItem('not', 'notice');
      this.$router.push({
        path: this.selectOption,
        query: {
          ArticleTitel: this.searchInfo
        }
      });
      this.$emit('searchOc', this.searchInfo);
      return;
      this.getIbVideoContent(this.selectOption, this.searchInfo);
    },
    goBranchFirst(id) {
      let select = id;
      localStorage.setItem('id', select);
      this.$router.push({
        path: '/'
      });
      let a = document.getElementById(id);
      let distance = 0;
      let total = a.offsetTop;
      let step = total / 30;
      jump();
      function jump() {
        if (distance < total) {
          distance += step;
          document.documentElement.scrollTop = distance;
          window.pageXOffset = distance;
          setTimeout(jump, 10);
        } else {
          document.documentElement.scrollTop = total;
          document.body.scrollTop = total;
          window.pageXOffset = total;
        }
      }
    },
    getIbPngContent() {
      getIbPngContentHomeList({
        pageIndex: 1,
        pageSize: 100,
        ArticleTitel: this.searchInfo
      }).then(res => {
        this.list1 = res.rows;
        // console.log(this.list1)
      });
    },
    getIbVideoContent() {
      getIbVideoContentHomeList({
        pageIndex: 1,
        VideoTitel: this.searchInfo,
        pageSize: 100
      }).then(res => {
        this.list = res.rows;
        // console.log(this.list)
      });
    },
    getbasiesMedias() {
      getbasiesMediasList({}).then(res => {
        this.JuZhen = res;
      });
    },
    // 分会接口
    getIbBranches() {
      getIbBranchesHomeList({
        pageIndex: 1,
        pageSize: 16
      }).then(res => {
        this.listFenHui = res;
        // console.log(this.list, '+++++++++++++++++++++++++list')
      });
    }
  },
  watch: {
    $route(to, from) {
      this.path = to.fullPath;
      // console.log(to, from)
    }
  }
};