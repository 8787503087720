import PublicFooter from '@/components/PublicFooter.vue';
import BPublicHeader from '@/components/BPublicHeader.vue';
import BannerSecond from '@/components/banner/BannerSecond.vue';
import BannerNinth from '@/components/banner/BannerNinth.vue';
import BannerSeven from '@/components/banner/BannerSeven.vue';
import { getIbBranchPngHomeList, getIbActivityHomeList, getIbBranchPngHomeListDetail } from '@/api/index.js';
export default {
  name: 'branch',
  components: {
    BPublicHeader,
    PublicFooter,
    BannerSecond,
    BannerNinth,
    BannerSeven
  },
  data() {
    return {
      // 分会活动
      listFH: [],
      // 分会通知
      listTZ: [],
      // 分会活动
      listHD: [],
      // 科研出版
      listKY: [],
      // 项目评优
      listpy: [{
        id: 1,
        name: '评优公告'
      }, {
        id: 2,
        name: '获奖名单'
      }, {
        id: 3,
        name: '优秀项目'
      }, {
        id: 4,
        name: '评选专家'
      }, {
        id: 5,
        name: '表彰现场'
      }],
      // 分会活动菜单栏
      listfh: [{
        id: 1,
        name: '重大年会'
      }, {
        id: 2,
        name: '行业峰会'
      }, {
        id: 3,
        name: '电气中国行'
      }, {
        id: 4,
        name: 'IBE TALKS'
      }, {
        id: 5,
        name: '百城百院'
      }, {
        id: 6,
        name: '走进工厂'
      }],
      listky: [{
        id: 1,
        name: '图书'
      }, {
        id: 2,
        name: '图集'
      }, {
        id: 3,
        name: '标准'
      }, {
        id: 4,
        name: '报告'
      }],
      activeNum: 0,
      activeXM: 0,
      activeKY: 0,
      listPY: [],
      BranName: '',
      changeBranch: '中国勘察设计协会电气分会'
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
    // 分会接口
    // this.getIbBranches()
    // 分会通知
    this.getIbBranchPng();
    // 分会活动
    this.getIbActivity('重大年会');
    //detail 分会通知 项目优品
    this.getIbBranchPngDetail('评优公告');
    // 科研成果 图书 图集
    this.getIbBranchPngDetailKy('图书');
  },
  methods: {
    // 项目评优
    active(i) {
      this.activeXM = i;
      this.getIbBranchPngDetail(this.listpy[this.activeXM].name);
    },
    getIbBranchPngDetail(plateName) {
      this.listPY = [];
      getIbBranchPngHomeListDetail({
        pageIndex: 1,
        pageSize: 50,
        plateName: plateName,
        BranchName: this.BranName
      }).then(res => {
        this.listPY = res.rows;
        // console.log(this.listPY)
      });
    },
    // 分会接口
    // getIbBranches(){
    //   getIbBranchesHomeList({}).then((res)=>{
    //     this.listFH=res
    //   })
    // },
    // 分会活动
    changeFH(i) {
      this.activeNum = i;
      this.getIbActivity(this.listfh[this.activeNum].name);
    },
    getIbActivity(columnName) {
      this.listFH = [];
      getIbActivityHomeList({
        pageIndex: 1,
        pageSize: 50,
        columnName: columnName,
        branchName: this.BranName
      }).then(res => {
        this.listFH = res;
        // console.log(this.listFH)
      });
    },
    //科研出版 图书 图集 标准
    changeKY(i) {
      this.activeKY = i;
      this.getIbBranchPngDetailKy(this.listky[this.activeKY].name);
      // console.log(this.listky[this.activeNum].name)
    },
    getIbBranchPngDetailKy(plateName) {
      ;
      this.listKY = [], getIbBranchPngHomeListDetail({
        pageIndex: 1,
        pageSize: 50,
        plateName: plateName,
        branchName: this.BranName
      }).then(res => {
        this.listKY = res.rows;
        // console.log(this.listKY)
      });
    },
    // 分会通知
    getIbBranchPng() {
      this.listTZ = [];
      getIbBranchPngHomeList({
        pageIndex: 1,
        pageSize: 50,
        PlateName: '分会通知',
        branchName: this.BranName
      }).then(res => {
        this.listTZ = res.rows;
        // console.log(this.listTZ)
      });
    }
  }
};