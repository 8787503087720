import CPublicHeader from '@/components/CPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import { GetApplyCompanyTypeData, GetApplyCompanyData } from '@/api/index';
export default {
  name: 'JudgingPanel',
  components: {
    CPublicHeader,
    PublicFooter
  },
  data() {
    return {
      list: [{
        Id: 1,
        Name: '主任及副主任',
        CreateTime: '2022-08-18 11:07:45'
      }, {
        Id: 2,
        Name: '监事会',
        CreateTime: '2022-08-18 11:07:45'
      }, {
        Id: 4,
        Name: '设计院专家委员',
        CreateTime: '2022-08-18 11:07:45'
      }, {
        Id: 5,
        Name: '企业专家委员',
        CreateTime: '2022-08-18 11:07:45'
      }],
      //评委会会员
      MemberList: [],
      //评委会右侧数据
      activeNum: 0
    };
  },
  mounted() {
    // this.getList() //获取评委会会员
    this.getMemberList(1); //获取成员列表
  },
  methods: {
    //点击切换会员
    active(i) {
      this.activeNum = i;
      this.getMemberList(this.list[this.activeNum].Id);

      // console.log(this.list[this.activeNum].Id)
    },
    //获取评委会会员
    getList() {
      this.list = [];
      GetApplyCompanyTypeData().then(res => {
        this.list = res.Data;
        // console.log(this.list, '---------list')
      });
    },
    //获取成员列表
    getMemberList(TypeId) {
      this.MemberList = [];
      GetApplyCompanyData({
        TypeId: TypeId
      }).then(res => {
        this.MemberList = res.Data.GetJudgingpanelData;
        // console.log(this.MemberList, 'Memberlist');
      });
    }
  }
};