import CPublicHeader from '@/components/CPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import { WebSiteGetWangjieHuiguData } from '@/api/index';
export default {
  name: 'Review',
  components: {
    CPublicHeader,
    PublicFooter
  },
  data() {
    return {
      list: [] //往届回顾
    };
  },
  mounted() {
    this.getlist(); //获取往届回顾数据
  },
  methods: {
    //获取往届回顾数据
    getlist() {
      this.list = [];
      WebSiteGetWangjieHuiguData().then(res => {
        this.list = res.Data;
      });
    }
  }
};