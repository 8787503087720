import { getIbBranchesHomeList } from '@/api/index.js';
export default {
  name: 'BPublicHeader',
  components: {},
  data() {
    return {
      list: [{
        id: 1,
        name: '首页',
        path: '/branch'
      }, {
        id: 2,
        name: '关于分会',
        path: '/aboutbranch'
      }, {
        id: 3,
        name: '分会通知',
        path: '/BranchNotice'
      }, {
        id: 4,
        name: '项目评优',
        path: '/ItemEvaluation'
      }, {
        id: 5,
        name: '分会会员',
        path: '/member'
      }, {
        id: 6,
        name: '科研出版',
        path: '/achievement'
      }, {
        id: 7,
        name: '申请入会',
        path: '/apply'
      }, {
        id: 8,
        name: '联系我们',
        path: '/Contact'
      }],
      activeNum: 0,
      BranName: '',
      branchName: '',
      listRY: []
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
    this.list[0].name = this.BranName;
    // console.log(this.list[0].name, '+++++++++++++++toubu')
    // localStorage.setItem('branchName', this.BranName)
    this.getIbBranches();
  },
  methods: {
    active(i, e) {
      this.activeNum = i;
      // console.log(i)
      // this.$router.push({
      //   path: e,
      //   query: {
      //     BranName: this.BranName
      //   }
      // })
      // console.log(this.activeNum)
      // console.log(this.list[this.activeNum].name)
    },
    // 荣誉照片
    getIbBranches() {
      this.listRY = [];
      getIbBranchesHomeList({
        pageIndex: 1,
        pageSize: 50,
        BranName: this.BranName
      }).then(res => {
        this.listRY = res[0].backgroundUrls;
        // console.log(this.listRY)
      });
    }
  }
};