import { WebSiteGetAwardstableData } from '@/api/index.js';
export default {
  name: 'topten',
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.getContent(); //获取申报条件和流程
  },
  methods: {
    //获取申报条件和流程
    getContent() {
      this.list = [];
      WebSiteGetAwardstableData({
        typename: '竞争力十强品牌'
      }).then(res => {
        this.list = res.Data;
        // console.log(this.list, '-------list')
      });
    }
  }
};