import BPublicHeader from '@/components/BPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import { getIbAboutUsList } from '@/api/index';
export default {
  name: 'Contact',
  components: {
    BPublicHeader,
    PublicFooter
  },
  data() {
    return {
      list: [],
      BranName: ''
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
    this.getIbAboutUs();
  },
  methods: {
    getIbAboutUs() {
      this.list = [];
      getIbAboutUsList({
        brName: this.BranName
      }).then(res => {
        this.list = res;
        // console.log(this.list)
      });
    }
  }
};