import BPublicHeader from '@/components/BPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import { getIbBranchPngHomeList } from '@/api/index';
export default {
  name: 'BranchNotice',
  components: {
    BPublicHeader,
    PublicFooter
  },
  data() {
    return {
      // 分会通知
      list: [],
      page: {
        Index: 1,
        Size: 10,
        Total: 50
      },
      BranName: ''
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined || this.BranName == 'undefined') {
      this.BranName = '中国勘察设计协会电气分会';
    }
    this.getIbBranchPng();
  },
  methods: {
    getIbBranchPng() {
      this.list = [];
      getIbBranchPngHomeList({
        pageIndex: this.page.Index,
        pageSize: this.page.Size,
        plateName: '分会通知',
        branchName: this.BranName
      }).then(res => {
        this.list = res.rows;
        this.page.Total = res.total;
        // console.log(this.list)
      });
    },
    handleCurrentChange(val) {
      this.page.Index = val;
      // console.log(`当前页: ${val}`)
      this.getIbBranchPng();
    }
  }
};