import "core-js/modules/es.array.push.js";
// @ is an alias to /src
import PublicFooter from '@/components/PublicFooter.vue';
import PublicHeader from '@/components/PublicHeader.vue';
import BannerFirst from '@/components/banner/BannerFirst.vue';
import BannerSecond from '@/components/banner/BannerSecond.vue';
import BannerFifth from '@/components/banner/BannerFifth.vue';
import { getIbBranchesHomeList, getIbVideoContentHomeList, getIbPngContentHomeList, getIbBranchPngHomeList, getIbActivityHomeList, GetApplyProTypeSelectData, GetGetApplyTypeSelectData, GetArticleData } from '@/api/index.js';
import { ref } from 'vue';
import { Loading } from 'element-plus/es/components/loading/src/service';
export default {
  name: 'home',
  components: {
    PublicFooter,
    PublicHeader,
    BannerFirst,
    BannerSecond,
    BannerFifth
  },
  data() {
    return {
      // C板块头部切换list
      CTitleList: [],
      // C板块左侧列表
      CLeftList: [],
      // C板块内容列表
      CContentList: [],
      // 分会接口
      list: [],
      // 活动专题 切换显示数据
      list1: [],
      // 精彩视频
      list2: [],
      // 分会通知
      listTZ: [],
      //
      urlList: [],
      news: [],
      // 通知公告
      news1: [],
      // 前沿资讯
      news2: [],
      // 大咖说
      news3: [],
      // 研究报告
      news4: [],
      // 活动专题 行业峰会 电器中国行
      listDetail: [{
        id: 1,
        name: '重大年会'
      }, {
        id: 2,
        name: '行业峰会'
      }, {
        id: 3,
        name: '电气中国行'
      }, {
        id: 4,
        name: 'IBE TALKS'
      }, {
        id: 5,
        name: '百城百院'
      }, {
        id: 6,
        name: '走进工厂'
      }],
      activeNum: 0,
      changeBranch: '中国勘察设计协会电气分会',
      CBranch: '行业品牌榜',
      CLeftBranch: '配电变压器',
      centerDialogVisible: ref(false)
    };
  },
  props: {
    id: String
  },
  setup() {
    return {
      // 顶部轮播
      swiper1: [],
      // 中部轮播
      swiper2: [],
      // 前沿资讯轮播
      swiper3: [],
      swiperheight: ''
    };
  },
  created() {
    this.$nextTick(() => {
      this.getlocal();
    });
  },
  // 调用请求数据的方法
  mounted() {
    // document.body.scrollTop = document.documentElement.scrollTop = 0
    // 分会通知
    this.getIbBranches();
    // 精彩视频
    this.getIbVideoContent();
    // 通知公告、资讯、大咖说、研究报告
    this.getIbPngContent();
    // 分会通知
    this.getIbBranchPng('中国勘察设计协会电气分会');
    // 活动专题 行业峰会 电器中国行
    this.getIbActivity('重大年会');
    this.$nextTick(function () {
      window.addEventListener('scroll', this.handleScroll);
    });
    // 25个产品类别data
    this.GetTypeSelect();
    // 中国工程勘察设计行业建筑电气品牌竞争力十强数据
    this.GetArticle();
    // 顶部三个类别 （行业品牌榜、优秀科技创新产品、设计院优秀渠道工作者）
    this.GetGetApplyType();
    var that = this;
    // console.log('页面进入时的宽度：', document.body.clientWidth);
    this.swiperheight = document.body.clientWidth;
    if (document.body.clientWidth < 768) {
      this.dialogWidth = '100%';
    }
  },
  methods: {
    getlocal() {
      let select = localStorage.getItem('id');
      let elm = document.getElementById(select);
      if (select) {
        elm.scrollIntoView(true);
      }
    },
    // 锚点
    goBranchFirst(id) {
      let a = document.getElementById(id);
      let distance = 0;
      let total = a.offsetTop;
      let step = total / 30;
      jump();
      function jump() {
        if (distance < total) {
          distance += step;
          document.documentElement.scrollTop = distance;
          window.pageXOffset = distance;
          setTimeout(jump, 10);
        } else {
          document.documentElement.scrollTop = total;
          document.body.scrollTop = total;
          window.pageXOffset = total;
        }
      }
    },
    banner1(e) {
      // console.log(e,'++++++++++++++swiper1+')
      this.swiper1 = e;
      // console.log(this.swiper1)
    },
    banner2(a) {
      // console.log(a,'++++++++++++++swiper2+')
      this.swiper2 = a;
    },
    bannerZX(b) {
      // console.log(b, '++++++++++++++swiper3+')
      this.swiper3 = b;
    },
    // 活动专题点击切换数据显示
    changeTab(i) {
      this.activeNum = i;
      // console.log(this.listDetail[this.activeNum]);
      this.getIbActivity(this.listDetail[this.activeNum].name);
    },
    BranchChange(i) {
      this.changeBranch = i;
      // console.log(i, '++++++++++++++++++++++++i')
      this.getIbBranchPng(i);
    },
    // C板块行业品牌榜点击
    CBranchActive(i) {
      this.CBranch = i;
      // console.log(i,'---------i')
      this.GetArticle(this.CBranch, this.CLeftBranch);
    },
    // C板块左侧tab类切换
    CLeftChange(i) {
      this.CLeftBranch = i;
      // console.log(i,'----------i')
      this.GetArticle(this.CBranch, this.CLeftBranch);
    },
    // 分会接口
    getIbBranches() {
      getIbBranchesHomeList({
        pageIndex: 1,
        pageSize: 16
      }).then(res => {
        this.list = res;
        // console.log(this.list, '+++++++++++++++++++++++++list')
      });
    },
    // 分会通知
    getIbBranchPng(BranName) {
      getIbBranchPngHomeList({
        pageIndex: 1,
        pageSize: 16,
        PlateName: '分会通知',
        BranchName: BranName
      }).then(res => {
        this.listTZ = res.rows;
        // console.log(this.listTZ.length)
      });
    },
    // 精彩视频
    getIbVideoContent() {
      getIbVideoContentHomeList({
        pageIndex: 1,
        pageSize: 16
      }).then(res => {
        this.list2 = res.rows;
        // console.log(this.list2)
      });
    },
    // 通知公告、资讯、大咖说、研究报告
    getIbPngContent() {
      this.news1 = [], this.news2 = [], this.news3 = [], this.news4 = [], getIbPngContentHomeList({
        pageIndex: 1,
        pageSize: 100
      }).then(res => {
        this.news = res.rows;
        // console.log(this.news)
        if (this.news.length > 0) {
          this.news.forEach(element => {
            // 通知公告
            if (element.PlateName == '通知公告') {
              this.news1.push(element);
              // console.log(this.news1)
            }
            // 前沿资讯
            if (element.PlateName == '前沿资讯') {
              this.news2.push(element);
              // console.log(this.news2)
              // Object.keys(ojb).length
              // this.$set(this.banner[2],this.banner[2].length,element)
            }
            // 大咖说
            if (element.PlateName == '大咖说') {
              this.news3.push(element);
              // console.log(this.news3)
            }
            // 研究报告
            if (element.PlateName == '研究报告') {
              this.news4.push(element);
            }
          });
        }
      });
    },
    // 活动专题 行业峰会  电器中国行
    getIbActivity(columnName) {
      this.list1 = [];
      getIbActivityHomeList({
        pageIndex: 1,
        pageSize: 16,
        columnName: columnName,
        branchName: '中国勘察设计协会电气分会'
      }).then(res => {
        this.list1 = res;
        // this.urlList = res.BannerUrl
        // console.log(this.urlList)
        // console.log(this.list1)
      });
    },
    // 顶部三个类别 （行业品牌榜、优秀科技创新产品、设计院优秀渠道工作者）
    GetGetApplyType() {
      this.CTitleList = [];
      GetGetApplyTypeSelectData().then(res => {
        this.CTitleList = res;
      });
    },
    // 25个产品类别data
    GetTypeSelect() {
      this.CLeftList = [];
      GetApplyProTypeSelectData().then(res => {
        this.CLeftList = res;
      });
    },
    // 中国工程勘察设计行业建筑电气品牌竞争力十强数据
    GetArticle() {
      if (this.CBranch != '' && this.CLeftBranch != '') {
        this.CContentList = [];
        GetArticleData({
          sysname: this.CLeftBranch,
          proname: this.CBranch
        }).then(res => {
          this.CContentList = res.Data;
          // console.log(this.CContentList, '-----------CContentList')
        });
      }
    },
    ToPinPai() {
      this.$router.push('/Brandfocus');
      let text = this.$router.resolve({
        path: '/Brandfocus'
      });
      // 打开一个新的页面
      window.open(text.href, '_blank');
    }
  },
  unmounted() {
    localStorage.setItem('id', '');
  }
};