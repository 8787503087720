import BPublicHeader from '@/components/BPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import { getIbBranchPngHomeList } from '@/api/index';
export default {
  name: 'BranchImageText',
  components: {
    BPublicHeader,
    PublicFooter
  },
  data() {
    return {
      // 分会通知
      list: [],
      idlist: [],
      BranName: '',
      Id: '',
      metaname: '',
      PlateName: ''
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined || this.BranName == 'undefined') {
      this.BranName = '中国勘察设计协会电气分会';
    }
    this.ArticleTitel = this.$route.query.ArticleTitel;
    this.Id = this.$route.query.Id;
    this.ActiTitle = this.$route.query.ActiTitle;
    this.CreateTime = this.$route.query.CreateTime;
    this.ActiNote = this.$route.query.ActiNote;
    this.RichText = this.$route.query.RichText;
    // this.CoverPngUrl = this.$route.query.CoverPngUrl
    this.PlateName = this.$route.query.PlateName;
    this.Title = this.$route.query.Title;
    this.getIbBranchPng();
    this.getIbBranchPngDetailById();
  },
  methods: {
    getIbBranchPngDetailById() {
      this.idlist = [];
      getIbBranchPngHomeList({
        pageIndex: 1,
        pageSize: 100,
        id: this.Id
      }).then(res => {
        this.idlist = res.rows;
        this.metaname = res.rows[0].ArticleTitel;
        this.PlateName = res.rows[0].PlateName;
        // console.log(this.idlist)
      });
    },
    getIbBranchPng() {
      this.list = [];
      // getIbBranchPngHomeList({ pageIndex: 1, pageSize: 100, plateName: '分会通知', branchName: this.BranName }).then(res => {
      getIbBranchPngHomeList({
        pageIndex: 1,
        pageSize: 100,
        plateName: '分会通知',
        branchName: this.BranName
      }).then(res => {
        this.list = res.rows;
        // console.log(this.list)
      });
    }
  }
};