import { WebSiteGetBrandFocusData } from '@/api/index.js';
export default {
  name: 'Support',
  data() {
    return {
      dataList: [] //获取具体展示数据
    };
  },
  mounted() {
    this.getList(); //具体单位展示数据
  },
  methods: {
    getList() {
      this.dataList = [];
      WebSiteGetBrandFocusData({
        TypeName: '组织机构-合作支持机构'
      }).then(res => {
        this.dataList = res.Data;
      });
    }
  }
};