import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import VueWechatTitle from 'vue-wechat-title'

import './css/base.css'
import './css/common.css'
import './css/B01-common.css'

import '../node_modules/swiper/swiper.scss'
import '../node_modules/swiper/vue/swiper.js'

import axios from './util/request.js';
import $ from 'jquery'
// 页面刷新到顶部
router.beforeEach((to, from, next) => {
    // 路由跳转后，让页面回到顶部
    // chrome
    document.body.scrollTop = 0; // firefox
    document.documentElement.scrollTop = 0; // safari
    window.pageYOffset = 0; // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next();
    //to 即将进入的路由
    //from 即将离开的路由
    //next 放行
});
// 处理页面跳转后不在顶部问题
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
// 全局注册 $

const app = createApp(App);
// app.config.globalProperties.hostUrl = 'https://ggmb.jsflcyppaa.com'  //绑定实例

// app.config.globalProperties.hostUrl = 'http://localhost:8080/' //绑定实例
app.config.globalProperties.hostUrl = 'http://www.ib-china.com/' //绑定实例
app.use(router).use(VueWechatTitle).mount("#app");
// createApp(App).use(router).mount('#app')
// App.config.globalProperties.$axios = axios;
