import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8c8f4c4a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: ""
};
const _hoisted_3 = {
  class: "type-page"
};
const _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("header", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.listRY, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "flex bg-header",
      key: item,
      style: _normalizeStyle({
        background: 'url(' + item.url + ')no-repeat center center'
      })
    }, null, 4);
  }), 128))])]), _createElementVNode("nav", null, [_createElementVNode("div", _hoisted_3, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (item, i) => {
    return _openBlock(), _createElementBlock("li", {
      key: i,
      onClick: $event => $options.active(i),
      style: _normalizeStyle(item.name == '项目评优' && this.BranName == '中国建筑节能协会电气分会' ? 'display:none;' : '')
    }, [_createVNode(_component_router_link, {
      style: {
        "display": "block",
        "color": "#fff"
      },
      to: item.path + '?BranName=' + this.BranName,
      onClick: $event => $options.active(i)
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
      _: 2
    }, 1032, ["to", "onClick"])], 12, _hoisted_4);
  }), 128))])])])]);
}