import PublicHeader from '@/components/PublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import indexSwiper from '@/components/indexSwiper.vue';
import { getIbVideoContentHomeList, getIbPngContentHomeList, sysLablesList, WebSiteGetVideoContentList } from '@/api/index.js';
export default {
  emits: ['searchOc'],
  name: 'videos',
  components: {
    PublicHeader,
    PublicFooter,
    indexSwiper
    // BannerFirst
  },
  data() {
    return {
      // 视频标签list
      labelList: [],
      // 精彩视频
      list: [],
      // 前沿资讯
      listZX: [],
      page: {
        Index: 1,
        Size: 15,
        Total: 60
      },
      VideoTitle: '',
      Label: '',
      activeNum: 0,
      alert: '',
      img: ''
    };
  },
  mounted() {
    this.VideoTitle = this.$route.query.VideoTitle;
    this.getIbVideoContent(), this.getIbPngContent(), this.getLabel(),
    //获取视频标签
    this.getLabelContent(0); //获取内容by标签
  },
  methods: {
    // 点击切换标签类
    active(i) {
      this.activeNum = i;
      this.getLabelContent(this.labelList[this.activeNum].Id); //获取内容by标签
    },
    searchLabel(item) {
      this.Label = item;
      // console.log(this.Label)
      getIbVideoContentHomeList({
        pageIndex: this.page.Index,
        pageSize: this.page.Size,
        LabelName: this.Label
      }).then(res => {
        this.list = res.rows;
        this.page.Total = res.total;
      });
    },
    search(e) {
      // console.log(e, '++++++++++++++++++222222')
      this.VideoTitle = e;
      this.getIbVideoContent();
    },
    getIbVideoContent() {
      // getIbVideoContentHomeList({ pageIndex: 1, pageSize: 16 }).then(res => {
      //   this.list = res.rows
      //   // console.log(this.list)
      // })
      this.list = [];
      getIbVideoContentHomeList({
        pageIndex: this.page.Index,
        pageSize: this.page.Size,
        VideoTitle: this.VideoTitle,
        LabelName: this.Label
      }).then(res => {
        // console.log(res, '---------res')
        this.list = res.rows;
        this.page.Total = res.total;
        if (res.rows == '') {
          // alert('没有搜索到具体内容')
          ;
          this.alert = '没有搜索到具体内容,请重新输入有效信息', this.img = '	https://gw.alicdn.com/imgextra/i3/O1CN01DKaORe1dcIHH0eMRx_!!6000000003756-55-tps-164-142.svg';
        }
      });
    },
    handleCurrentChange(val) {
      this.page.Index = val;
      // console.log(`当前页: ${val}`)
      this.getIbVideoContent();
    },
    getIbPngContent() {
      this.listZX = [];
      getIbPngContentHomeList({
        pageIndex: 1,
        pageSize: 50,
        PlateName: '前沿资讯'
      }).then(res => {
        this.listZX = res.rows;
        // console.log(this.listZX)
      });
    },
    // 获取视频标签
    getLabel() {
      this.labelList = [];
      sysLablesList().then(res => {
        this.labelList = res;
      });
    },
    // 获取具体内容by标签
    getLabelContent(SysLabelId) {
      // console.log(SysLabelId,'-------SysLabelId')
      this.list = [];
      WebSiteGetVideoContentList({
        SysLabelId: SysLabelId
      }).then(res => {
        this.list = res.Data;
      });
    }
  },
  watch: {
    $route(to, from) {
      window.location.reload(); //监测到路由发生跳转时刷新一次页面
    }
  }
};