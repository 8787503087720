import "core-js/modules/es.array.push.js";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from '../../../node_modules/swiper/vue/swiper-vue.js';

// Import Swiper styles
import '../../../node_modules/swiper/swiper-bundle.css';
import { getBannerPngHomeList } from '../../api/index.js';
export default {
  name: 'BannerThird',
  props: {
    list: {
      type: Array
    },
    imgnum: {
      type: Number
    },
    imgh: {
      type: Number
    },
    imgw: {
      type: Number
    },
    clickable: {
      type: Boolean
    },
    text: {
      type: String
    },
    // address: {
    //   type: String
    // },
    imgJG: {
      type: Number
    },
    CoverPngUrl: {
      type: String
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      bannerlist: [],
      // 顶部
      banner1: [],
      // 中部
      banner2: []
    };
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay]
    };
  },
  mounted() {
    this.getBannerPng();
  },
  methods: {
    // 轮播图顶部 中部
    getBannerPng() {
      this.banner1 = [];
      this.banner2 = [];
      getBannerPngHomeList({}).then(res => {
        this.bannerlist = res;
        // console.log(this.bannerlist)
        if (this.bannerlist.length > 0) {
          this.bannerlist.forEach(element => {
            // 顶部轮播
            if (element.Location == '顶部') {
              this.banner1.push(element);
              this.$emit('bannerListOC', this.banner1);
              // console.log(this.banner1, 'banner1')
            }
            if (element.Location == '中部') {
              this.banner2.push(element);
              this.$emit('bannerListMD', this.banner2);
              // console.log(this.banner2, '+++++++++++')
            }
          });
        }
      });
    }
  }
};