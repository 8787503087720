import "core-js/modules/es.array.push.js";
import PublicFooter from '@/components/PublicFooter.vue';
import BPublicHeader from '@/components/BPublicHeader.vue';
import { getSysGroupVipsHomeList, getIbUserVipsHomeList, getIbUserVipsHomeMemberPgaeList } from '@/api/index';
export default {
  name: 'member',
  components: {
    BPublicHeader,
    PublicFooter
  },
  data() {
    return {
      //左侧会员管理
      listHY: [],
      // 右侧会员展示
      listGroupName: [],
      listZS: [],
      listCY: [],
      page: {
        Index: 1,
        Size: 10,
        Total: 500
      },
      activeNum: 1,
      BranName: '',
      member: false,
      GroupName: [],
      changeBranch: '分会领导',
      labelName: ''
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
    if (this.BranName == '中国勘察设计协会电气分会') {
      // 左侧会员管理
      this.getSysGroupVips();
    }
    if (this.BranName == '中国建筑节能协会电气分会') {
      this.listHY = [{
        value: '1',
        label: '分会领导',
        children: null
      }, {
        value: '3',
        label: '秘书处',
        children: null
      }, {
        value: '4',
        label: '常务委员',
        children: null
      }, {
        value: '5',
        label: '委员',
        children: null
      }, {
        value: '6',
        label: '双高专家组',
        children: null
      }, {
        value: '7',
        label: '青年专家组',
        children: null
      }];
    }
    if (this.BranName == '北京物联网学会建筑物联网分会') {
      this.listHY = [{
        value: '1',
        label: '分会领导',
        children: null
      }, {
        value: '3',
        label: '秘书处',
        children: null
      }, {
        value: '4',
        label: '常务委员',
        children: null
      }, {
        value: '5',
        label: '委员',
        children: null
      }];
    }

    // // 右侧会员展示
    // this.getIbUserVips(1, '分会领导')
    // 自动展开下拉框
    // this.$refs.selectRef.toggleMenu()
    this.active(1, '分会领导');
  },
  methods: {
    active(i, label) {
      this.labelName = label;
      // console.log(this.labelName,'------------this.labelName');
      this.member = false;
      this.activeNum = i;
      // console.log(this.activeNum,i)
      this.getIbUserVips(parseInt(i));
      let lid = 0;
      if (label == '常务理事') {
        lid = 14;
      } else if (label == '理事') {
        lid = 15;
      } else if (label == '双高专家组') {
        lid = 18;
      } else if (label == '青年专家组') {
        lid = 22;
      }
      if (lid != 0) {
        this.getIbUserVipsHomeMemberPgae(parseInt(lid));
      }
    },
    getIbUserVipsHomeMemberPgae(lid) {
      getIbUserVipsHomeMemberPgaeList({
        pageIndex: 1,
        pageSize: 500,
        id: lid,
        brName: this.BranName
      }).then(res => {
        // console.log(res,'-------res')
        this.listCY = res.rows;
        this.page.Total = res.total;
        this.member = true;
        // console.log(this.listCY,'-------listCY')
      });
    },
    getSysGroupVips() {
      this.listHY = [];
      getSysGroupVipsHomeList({}).then(res => {
        this.listHY = res;
        // console.log(this.listHY)
      });
    },
    getIbUserVips(id) {
      this.listZS = [];
      getIbUserVipsHomeList({
        Pid: id,
        brName: this.BranName
      }).then(res => {
        this.listZS = res.rows;
        this.listCY = [];
        this.listZS.forEach(element => {
          // 通知公告
          if (element.GroupName == '组员') {
            this.listCY.push(element);
            // console.log(this.listCY)
          }
        });
        // console.log(JSON.stringify(res.rows))
        this.GroupName = res.typeListSplit;
        // console.log(JSON.stringify(res.typeListSplit), '----------res.typeListSplit')
      });
    },
    handleCurrentChange(val) {
      this.page.Index = val;
      // console.log(`当前页: ${val}`)
      this.getIbUserVipsHomeMemberPgae();
    }
  }
};