import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c6a74698"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_swiper_slide = _resolveComponent("swiper-slide");
  const _component_swiper = _resolveComponent("swiper");
  return _openBlock(), _createBlock(_component_swiper, {
    modules: $setup.modules,
    "slides-per-view": $props.imgnum,
    "space-between": $props.imgJG,
    navigation: ""
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, item => {
      return _openBlock(), _createBlock(_component_swiper_slide, {
        key: item.id,
        class: "flex column"
      }, {
        default: _withCtx(() => [_createElementVNode("a", {
          target: "_blank",
          href: item.ExternalUrl === '' ? _ctx.javascript : item.ExternalUrl,
          class: "imgSwiper",
          style: _normalizeStyle({
            background: 'url(' + item.CoverPngUrl + ')no-repeat center center'
          })
        }, null, 12, _hoisted_1)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }, 8, ["modules", "slides-per-view", "space-between"]);
}