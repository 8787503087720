import "core-js/modules/es.array.push.js";
import PublicFooter from '@/components/PublicFooter.vue';
import BPublicHeader from '@/components/BPublicHeader.vue';
import address from '@/util/address.json';
import { ref } from 'vue';
export default {
  name: 'InfoReviseSecond',
  components: {
    BPublicHeader,
    PublicFooter
  },
  data() {
    return {
      //  省数据
      addressData: [],
      //  市数据
      cityData: [],
      // 区数据
      areaData: [],
      applyInfo: [],
      DataForm: {
        CompanyName: '',
        Province: '',
        City: '',
        County: '',
        DetailInfo: '',
        CompanyUrl: '',
        CompanyPeople: '',
        // 单位法人
        RegisteredCapital: '',
        // 注册资金
        YeatOutValue: '',
        // 年产值
        JobPeopleCount: '',
        // 职工人数
        UnitNature: '',
        // 单位性质
        UnitType: '',
        //单位分类
        Industry: '',
        // 行业
        CompanyDetail: '',
        SupportMode: '',
        // 支持方式
        sdPng: '',
        SDName: '',
        // 理事代表1姓名
        SDPost: '',
        // 职务
        D1ID: '',
        // 身份证号
        D1Phone: '',
        D2Phone: '',
        D1Email: '',
        D1WeChar: '',
        D1Detail: '',
        GroupName: '理事申请',
        // 常务理事 理事
        ACNmae: '',
        // 分会名称
        typeName: '理事'
      },
      value3: ref([]),
      fileList: [],
      // 单位性质
      UnitNatureOption: [{
        value: '国有企业',
        label: '国有企业'
      }, {
        value: '民营企业',
        label: '民营企业'
      }, {
        value: '合资企业',
        label: '合资企业'
      }, {
        value: '外资企业',
        label: '外资企业'
      }, {
        value: '混改企业',
        label: '混改企业'
      }, {
        value: '其他',
        label: '其他'
      }],
      // 单位分类
      UnitTypeOption: [{
        value: '设计及咨询',
        label: '设计及咨询'
      }, {
        value: '设备及材料商',
        label: '设备及材料商'
      }, {
        value: '政府',
        label: '政府'
      }, {
        value: '研究机构',
        label: '研究机构'
      }, {
        value: '房地产',
        label: '房地产'
      }, {
        value: '投资商',
        label: '投资商'
      }],
      // 所属行业
      IndustryOption: [{
        value: '勘察',
        label: '勘察'
      }, {
        value: '建筑',
        label: '建筑'
      }, {
        value: '机械',
        label: '机械'
      }, {
        value: '轻工',
        label: '轻工'
      }, {
        value: '冶金',
        label: '冶金'
      }, {
        value: '石油、石化',
        label: '石油、石化'
      }, {
        value: '通信、电子',
        label: '通信、电子'
      }],
      rules: {
        // 基本资料
        CompanyName: [{
          required: true,
          message: '请输入单位名称',
          trigger: 'blur'
        }],
        DetailInfo: [{
          required: true,
          message: '请输入详细地址',
          trigger: 'blur'
        }],
        CompanyUrl: [{
          required: true,
          message: '请输入单位网址',
          trigger: 'blur'
        }],
        CompanyPeople: [{
          required: true,
          message: '请输入单位法人',
          trigger: 'blur'
        }],
        RegisteredCapital: [{
          required: true,
          message: '请输入注册资金',
          trigger: 'blur'
        }],
        YeatOutValue: [{
          required: true,
          message: '请输入年产值',
          trigger: 'blur'
        }],
        JobPeopleCount: [{
          required: true,
          message: '请输入职工人数',
          trigger: 'blur'
        }],
        CompanyDetail: [{
          required: true,
          message: '请输入单位简介',
          trigger: 'blur'
        }],
        SupportMode: [{
          required: true,
          message: '请输入支持方式',
          trigger: 'blur'
        }],
        // SDID: [{ required: true, pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号', trigger: 'blur' }],
        // SDPhone: [{ required: true, pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号', trigger: 'blur' }],
        // SDEmail: [{ required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: '请输入正确的邮箱', trigger: 'blur' }],
        // SDDetail: [{ required: true, message: '请输入理事代表简介', trigger: 'blur' }],
        sdPng: [{
          required: true,
          message: '请输入代表图片',
          change: 'blur'
        }],
        // duoselect: [{ type: array, required: true, message: '请选择', trigger: 'blur,change' }],

        // 理事代表
        SDName: [{
          required: true,
          message: '请输入代表姓名',
          trigger: 'blur'
        }],
        SDPost: [{
          required: true,
          message: '请输入代表职称',
          trigger: 'blur'
        }],
        D1ID: [{
          required: true,
          pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
          message: '请输入正确的身份证号',
          trigger: 'blur'
        }],
        D1Phone: [{
          required: true,
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: '请输入正确的手机号',
          trigger: 'blur'
        }],
        D1Email: [{
          required: true,
          pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: '请输入正确的邮箱',
          trigger: 'blur'
        }],
        D1WeChar: [{
          required: true,
          pattern: /^[A-Za-z0-9~!@#$%^&*()_+`\-={}:";'<>?,.\/]*$/,
          message: '请输入代表微信',
          trigger: 'blur'
        }],
        D1Detail: [{
          required: true,
          message: '请输入代表简介',
          trigger: 'blur'
        }]

        // select: [{ required: true, message: '不能为空', trigger: 'change', type: 'number' }]
      },
      BranName: ''
    };
  },
  created() {
    // 省份数据初始化
    this.addressData = address;
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
    this.DataForm.ACNmae = this.BranName;
  },
  methods: {
    // 省份更改
    changePro(e) {
      // 从省中过滤出市的数据
      this.cityData = this.addressData.filter(item => {
        return item.name == e;
      })[0].children;
      // 省发生改变的时候 清空输入框市区街道的内容
      this.DataForm.County = '';
      this.DataForm.City = '';
      // 省发生更改时 该表空区街道数据的内容
      this.areaData = [];
    },
    // 市更改
    changeCity(e) {
      // 获取到区的数据
      this.areaData = this.cityData.filter(item => item.name == e)[0].children;
      // 清空数据后面对应数组的数据
      this.DataForm.County = '';
    },
    // 区更改
    changeArea(e) {
      let temp = this.areaData.filter(item => item.name == e);
      // 获取到区的code码
      // this.DataForm.regionalNumber = temp[0].code
      // 获取到街道的数据
      // this.jdData = this.areaData.filter(item => item.name == e)[0].children
    },
    // 上传图片
    addImgsuccess(response, file, fileList) {
      this.DataForm.sdPng = response.Data;
      // console.log(response.Data, '++++++++++++++++uploadFile')
    },
    submitForm() {
      this.$refs['infoForm'].validate(valid => {
        if (valid) {
          alert('提交成功');
          if (this.DataForm.D1Phone == '') {
            this.DataForm.D1Phone = 0;
            // return this.DataForm.D1Phone
          }
          if (this.DataForm.D2Phone == '') {
            this.DataForm.D2Phone = 0;
            // return this.DataForm.D1Phone
          }
          localStorage.setItem('applyInfo', JSON.stringify(this.DataForm));
          this.$router.push('/ConfirmDataSecond?BranName=' + this.BranName);
        }
      });
    }
  }
};