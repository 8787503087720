// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from '../../../node_modules/swiper/vue/swiper-vue.js';

// Import Swiper styles
import '../../../node_modules/swiper/swiper-bundle.css';
import { getIbPngContentFrontHomeList } from '../../api/index.js';
export default {
  name: 'Bannerfirst',
  props: {
    list: {
      type: Array
    },
    imgnum: {
      type: Number
    },
    imgh: {
      type: Number
    },
    imgw: {
      type: Number
    },
    clickable: {
      type: Boolean
    },
    text: {
      type: String
    },
    address: {
      type: String
    },
    imgJG: {
      type: Number
    },
    CoverPngUrl: {
      type: String
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      bannerlist: [],
      // 前沿资讯轮播图
      bannerZX: []
    };
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay]
    };
  },
  mounted() {
    this.getIbPngContentFront();
  },
  methods: {
    // 前沿资讯轮播图
    getIbPngContentFront() {
      // this.bannerZX=[]
      getIbPngContentFrontHomeList({}).then(res => {
        this.bannerZX = res;
        this.$emit('bannerListZX', this.bannerZX);
      });
    }
  }
};