import { WebSiteGetAwardstableData } from '@/api/index.js';
export default {
  name: 'worker',
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.getContent(); //获取申报条件和流程
  },
  methods: {
    getContent() {
      WebSiteGetAwardstableData({
        typename: '设计院渠道优秀工作者'
      }).then(res => {
        this.list = res.Data;
      });
    }
  }
};