import CPublicHeader from '@/components/CPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import { GetApplyJuJiaoData, GetBrandFocusYearTypeData, GetBrandFocusData, getSystemList } from '@/api/index';
export default {
  name: 'Brandfocus',
  components: {
    PublicFooter,
    CPublicHeader
  },
  data() {
    return {
      systemlist: [],
      //系统类别
      timeList: [],
      //年份标签list
      Awardlist: [],
      //奖项类别
      dataList: [],
      //具体数据
      // activeSysNum: '电源系统',//选中类别index
      // activeNum: '2022年', // 年份index
      // activeNumT: '品牌十强', // 类别index
      activename: '',
      //2018 ， 2019 ， 2020
      Name: '',
      //搜索内容
      SystemTypeName: '',
      //系统类别
      ApplyYearTypeName: '',
      //年份
      ApplyTypeName: '',
      //品牌十强
      alert: '',
      img: ''
    };
  },
  mounted() {
    this.SystemTypeName = this.$route.query.SystemTypeName; //系统类别
    this.ApplyYearTypeName = this.$route.query.ApplyYearTypeName; //年份
    this.Name = this.$route.query.Name; //产品类别
    if (this.ApplyTypeName == '' || this.ApplyTypeName == undefined || this.ApplyTypeName == null) {
      this.ApplyTypeName = '品牌十强';
    }
    if (this.SystemTypeName == '' || this.SystemTypeName == undefined || this.SystemTypeName == null) {
      this.SystemTypeName = '电源系统';
    }
    if (this.ApplyYearTypeName == '' || this.ApplyYearTypeName == undefined || this.ApplyYearTypeName == null) {
      this.ApplyYearTypeName = '2023年';
    }
    this.getsystem(); //获取系统类别
    this.getYear(); // 获奖品牌年份
    this.getAwardType(); // 获奖类别
    this.getDataList(this.SystemTypeName, this.ApplyYearTypeName, this.ApplyTypeName, this.Name); // 获取具体数据
    // console.log(this.ApplyTypeName);
  },
  methods: {
    //点击切换 年份
    activeYear(i) {
      // console.log(i);
      this.ApplyYearTypeName = i;
      this.activename = this.ApplyYearTypeName;
      this.getDataList(this.SystemTypeName, this.ApplyYearTypeName, this.ApplyTypeName, this.Name);
    },
    //点击切换奖项
    activeType(i) {
      // console.log(i);
      this.ApplyTypeName = i;
      this.getDataList(this.SystemTypeName, this.ApplyYearTypeName, this.ApplyTypeName, this.Name);
    },
    activeSystem(i) {
      // console.log(i);
      this.SystemTypeName = i;
      this.getDataList(this.SystemTypeName, this.ApplyYearTypeName, this.ApplyTypeName, this.Name);
    },
    // 获奖品牌年份
    getYear() {
      this.timeList = [];
      GetBrandFocusYearTypeData({}).then(res => {
        this.timeList = res;
      });
    },
    // 获取系统类别
    getsystem() {
      getSystemList().then(res => {
        this.systemlist = res;
      });
    },
    // 获奖类别
    getAwardType() {
      this.Awardlist = [];
      GetApplyJuJiaoData({}).then(res => {
        this.Awardlist = res;
      });
    },
    // 获取具体数据
    getDataList(SystemTypeName, ApplyYearTypeName, ApplyTypeName, Name) {
      if (this.activename == '2018年' || this.activename == '2019年' || this.activename == '2020年') {
        ApplyTypeName = '品牌十强';
        SystemTypeName = '';
        this.SystemTypeName = '';
        this.dataList = [];
        GetBrandFocusData({
          Name: Name,
          ApplyYearTypeName: ApplyYearTypeName,
          ApplyTypeName: ApplyTypeName,
          SystemTypeName: SystemTypeName
        }).then(res => {
          // console.log(res);
          // this.dataList = res.Data
          // this.activename = res.Data[this.activeNum].Name
          if (res.Data == '' && this.activename != '2018年' && this.activename != '2019年' && this.activename != '2020年') {
            this.Name = '';
            this.alert = '没有搜索到具体内容,请重新输入有效信息', this.img = 'https://gw.alicdn.com/imgextra/i3/O1CN01DKaORe1dcIHH0eMRx_!!6000000003756-55-tps-164-142.svg';
          } else {
            this.dataList = res.Data;
            // this.activename = res.Data[this.activeNum].Name
            this.alert = '';
            this.img = '';
            this.Name = '';
          }
        });
      } else {
        if (ApplyYearTypeName != '' && ApplyTypeName != '') {
          this.dataList = [];
          GetBrandFocusData({
            Name: Name,
            ApplyYearTypeName: ApplyYearTypeName,
            ApplyTypeName: ApplyTypeName,
            SystemTypeName: SystemTypeName
          }).then(res => {
            // this.dataList = res.Data
            // this.activename = res.Data[this.activeNum].Name
            if (res.Data == '' && this.activename != '2018年' && this.activename != '2019年' && this.activename != '2020年') {
              this.Name = '';
              this.alert = '没有搜索到具体内容,请重新输入有效信息', this.img = 'https://gw.alicdn.com/imgextra/i3/O1CN01DKaORe1dcIHH0eMRx_!!6000000003756-55-tps-164-142.svg';
            } else {
              this.dataList = res.Data;
              // this.activename = res.Data[this.activeNum].Name
              this.alert = '';
              this.img = '';
              this.Name = '';
            }
          });
        }
      }
    }
  },
  watch: {
    $route(to, from) {
      window.location.reload(); //监测到路由发生跳转时刷新一次页面
    }
  }
};