// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import '../../../node_modules/swiper/swiper-bundle.css';
import { getIbBranchPngHomeListDetail } from '../../api/index.js';
export default {
  name: 'BannerSeven',
  props: {
    list: {
      type: Array
    },
    imgnum: {
      type: Number
    },
    imgh: {
      type: Number
    },
    imgw: {
      type: Number
    },
    clickable: {
      type: Boolean
    },
    text: {
      type: String
    },
    address: {
      type: String
    },
    imgJG: {
      type: Number
    },
    CoverPngUrl: {
      type: String
    },
    GroupNum: {
      type: Number
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      bannerlist: [],
      // 前沿资讯轮播图
      bannerZX: [],
      BranName: ''
    };
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay]
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
    this.getIbBranchPngDetail();
  },
  methods: {
    getIbBranchPngDetail(PlateName) {
      // this.bannerZX=[]
      getIbBranchPngHomeListDetail({
        pageIndex: 1,
        pageSize: 16,
        PlateName: PlateName,
        BranchName: this.BranName
      }).then(res => {
        this.listZX = res.rows;
        // console.log(this.listPY)
      });
    }
  }
};