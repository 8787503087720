import "core-js/modules/es.array.push.js";
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import CPublicHeader from '@/components/CPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import { GetApplyProTypeSelectData, GetGetApplyTypeSelectData, GetArticleData, WebSiteGetCplatebannerData, GetWebSiteScriptstatistic, GetBrandFocusYearTypeData, getChildList, getSystemList } from '@/api/index.js';
export default {
  name: 'bsan',
  components: {
    PublicFooter,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      systemlist: [],
      productlist: [],
      DataForm: {
        value1: '',
        value2: '',
        value3: ''
      },
      textInfo: [],
      //文本信息
      modules: [Navigation, A11y],
      searchInfo: '',
      //搜索信息
      changeBranch: '中国勘察设计协会电气分会',
      CBranch: '行业品牌榜',
      CLeftBranch: '配电变压器',
      // C板块头部切换list
      CTitleList: [],
      // C板块左侧列表
      CLeftList: [],
      // C板块内容列表
      CContentList: [],
      pictureurl: '',
      //c板块首页 顶部图
      bannerlist: [],
      //轮播图数组
      timeList: [{
        Id: 1,
        Name: '2021年'
      }, {
        Id: 2,
        Name: '2022年'
      }],
      indexinfo: {}
    };
  },
  mounted() {
    // 25个产品类别data
    this.GetTypeSelect();
    // 中国工程勘察设计行业建筑电气品牌竞争力十强数据
    this.GetArticle();
    // 顶部三个类别 （行业品牌榜、优秀科技创新产品、设计院优秀渠道工作者）
    this.GetGetApplyType();
    // 获取c搬开首页顶部照片
    this.getpicture();
    this.getbanner();
    // 获取文本信息
    this.getText();
    //this.getYear(); 获奖品牌年份
    this.getsystem(); //获取系统类别
  },
  methods: {
    // 获取系统类别
    getsystem() {
      getSystemList().then(res => {
        this.systemlist = res;
      });
    },
    //获取产品类别
    child(ParentId) {
      getChildList({
        ParentId: ParentId
      }).then(res => {
        this.productlist = res;
      });
    },
    submitForm() {
      this.$refs['infoForm'].validate(valid => {
        if (valid) {
          // alert('submit!');
          let routeUrl = this.$router.resolve({
            path: 'Brandfocus',
            query: {
              SystemTypeName: this.DataForm.value1,
              ApplyYearTypeName: this.DataForm.value2,
              Name: this.DataForm.value3,
              ApplyTypeName: '品牌十强'
            }
          });
          window.open(routeUrl.href, '_blank');
        } else if (this.DataForm.value1 == '' || this.DataForm.value1 == undefined || this.DataForm.value1 == null) {
          // console.log('error submit!!');
          alert('请选择具体选项进行搜索');
          return false;
        }
      });
    },
    // C板块行业品牌榜点击
    CBranchActive(i) {
      this.CBranch = i;

      // console.log(i,'---------i')
      this.GetArticle(this.CBranch, this.CLeftBranch);
    },
    // C板块左侧tab类切换
    CLeftChange(i) {
      this.CLeftBranch = i;
      // console.log(i,'----------i')
      this.GetArticle(this.CBranch, this.CLeftBranch);
    },
    // 顶部三个类别 （行业品牌榜、优秀科技创新产品、设计院优秀渠道工作者）
    GetGetApplyType() {
      this.CTitleList = [];
      GetGetApplyTypeSelectData().then(res => {
        this.CTitleList = res;
      });
    },
    // 25个产品类别data
    GetTypeSelect() {
      this.CLeftList = [];
      GetApplyProTypeSelectData().then(res => {
        this.CLeftList = res;
      });
    },
    // 中国工程勘察设计行业建筑电气品牌竞争力十强数据
    GetArticle() {
      if (this.CBranch != '' && this.CLeftBranch != '') {
        this.CContentList = [];
        GetArticleData({
          sysname: this.CLeftBranch,
          proname: this.CBranch
        }).then(res => {
          this.CContentList = res.Data;
          // console.log(this.CContentList, '-----------CContentList')
        });
      }
    },
    // 搜索
    // submit() {
    // 	let routeUrl = this.$router.resolve({
    // 		path: 'Brandfocus',
    // 		query: {
    // 			Name: this.searchInfo,
    // 			ApplyYearTypeName: '2023年',
    // 			ApplyTypeName: '品牌十强',
    // 		},
    // 	});
    // 	window.open(routeUrl.href, '_blank');
    // },
    // 获取C板块顶图 默认为1
    getpicture() {
      WebSiteGetCplatebannerData({
        IsPostion: 1
      }).then(res => {
        this.pictureurl = res.Data[0].PictureUrl;
      });
    },
    // 获取C板块轮播图 IsPostion为2
    getbanner() {
      WebSiteGetCplatebannerData({
        IsPostion: 2
      }).then(res => {
        this.bannerlist = res.Data;
      });
    },
    getText() {
      GetWebSiteScriptstatistic().then(res => {
        this.textInfo = res.Data;
      });
    },
    ToPinPai() {
      this.$router.push('/Brandfocus');
      let text = this.$router.resolve({
        path: '/Brandfocus'
      });
      // 打开一个新的页面
      window.open(text.href, '_blank');
    },
    // 获奖品牌年份
    getYear() {
      this.timeList = [];
      GetBrandFocusYearTypeData({}).then(res => {
        this.timeList = res;
      });
    }
  }
};