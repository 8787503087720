import { WebSiteGetBrandFocusData } from '@/api/index.js';
export default {
  name: 'Guidance',
  data() {
    return {
      dataList: [] //具体单位展示数据
    };
  },
  mounted() {
    this.getList('组织机构-指导单位'); //获取具体单位展示数据
  },
  methods: {
    //获取具体单位展示数据
    getList() {
      this.dataList = [];
      WebSiteGetBrandFocusData({
        TypeName: '组织机构-指导单位'
      }).then(res => {
        this.dataList = res.Data;
        // console.log(this.dataList,'--------        console.log(dataList)')
      });
    }
  }
};