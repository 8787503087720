import BannerThird from '@/components/banner/BannerThird.vue';
export default {
  name: 'indexSwiper',
  components: {
    BannerThird
  },
  data() {
    return {
      swiper1: []
    };
  },
  methods: {
    banner1(e) {
      // console.log(e,'++++++++++++++swiper1+')
      this.swiper1 = e;
      // console.log(this.swiper1)
    }
  }
};