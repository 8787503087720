import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router';

// ib-China主页
import index from '../views/ib-China/IndexView.vue';
import news from '../views/ib-China/NewsView.vue';
import videos from '../views/ib-China/VideosView.vue';
import VideoDetails from '../views/ib-China/DetailView.vue';
import topic from '../views/ib-China/TopicView.vue';
import ResearchReport from '../views/ib-China/ResearchReportView.vue';
import StarSaid from '../views/ib-China/StarSaidView.vue';
import ExhibitInfo from '../views/ib-China/ExhibitInfo.vue';
import search from '../views/bsan/SearchView.vue';
import ImageTextContent from '../views/ib-China/ImageTextContentView.vue';
import notice from '../views/ib-China/NoticeView.vue';
import ResearchDetail from '../views/ib-China/ResearchDetail.vue';

//ib-China分会
import branch from '../views/ib-China-branch/IndexBranchVIews.vue';
import aboutbranch from '../views/ib-China-branch/AboutBranchVIew.vue';
import member from '../views/ib-China-branch/MemberView.vue';
import achievement from '../views/ib-China-branch/AchievementView.vue';
import apply from '../views/ib-China-branch/ApplyView.vue';
import applyfirst from '../views/ib-China-branch/apply/ApplyFirst.vue';
import applysecond from '../views/ib-China-branch/apply/ApplySecond.vue';
import certificates from '../views/ib-China-branch/apply/CertificatesView.vue';
import CertificatesDownload from '../views/ib-China-branch/apply/CertificatesDownload.vue';
import Failed from '../views/ib-China-branch/apply/Failed.vue';
import change from '../views/ib-China-branch/apply/ChangeView.vue';
import notFound from '../views/ib-China-branch/apply/NotFound.vue';
import inforevise from '../views/ib-China-branch/InfoReviseView.vue';
import inforeviseSecond from '../views/ib-China-branch/InfoReviseSecondView.vue';
import BranchNotice from '../views/ib-China-branch/BranchNoticeView.vue';
import BranchNoticeDetail from '../views/ib-China-branch/BranchNoticeDetail.vue';
import BranchImageText from '../views/ib-China-branch/BranchImageText.vue';
import ItemEvaluation from '../views/ib-China-branch/ItemEvaluationView.vue';
import ConfirmData from '../views/ib-China-branch/ConfirmDataView.vue';
import ConfirmDataSecond from '../views/ib-China-branch/ConfirmDataSecond.vue';
import ApplyFinish from '../views/ib-China-branch/ApplyFinishView.vue';
import ApplyFinishViewSecond from '../views/ib-China-branch/ApplyFinishViewSecond.vue';
import Contact from '../views/ib-China-branch/ContactView.vue';
import AboutView from '../views/ib-China-branch/about/AboutView.vue';
import WorkRegulations from '../views/ib-China-branch/about/WorkRegulations.vue';
import MainJobView from '../views/ib-China-branch/about/MainJobView.vue';
import OrganizationFrame from '../views/ib-China-branch/about/OrganizationFrame.vue';

// bsan首页
import bsan from '../views/bsan/IndexBsanView.vue';
import organize from '../views/bsan/OrganizeView.vue';
import trends from '../views/bsan/TrendsVIew.vue';
import Award from '../views/bsan/Award.vue';
import worker from '../views/bsan/Award/worker.vue';
import newproduct from '../views/bsan/Award/newproduct.vue';
import topten from '../views/bsan/Award/topten.vue';
// import headline from '../views/bsan/HeadlineView.vue'
// import headlineDetail from '../views/bsan/HeadlineDetailView.vue'
import ProductIntroduction from '../views/bsan/ProductIntroduction.vue';
import PastReview from '../views/bsan/PastReviewView.vue';
import Guidance from '../views/bsan/organize/Guidance.vue';
import Sponsor from '../views/bsan/organize/Sponsor.vue';
import Support from '../views/bsan/organize/Support.vue';
import Brandfocus from '../views/bsan/Brandfocus.vue';
import Review from '../views/bsan/Review.vue';
import JudgingPanel from '../views/bsan/JudgingPanel.vue';
import ContactUs from '../views/bsan/ContactUs.vue';

import BannerFirst from '../components/banner/BannerFirst.vue';

//Vue.use(Router)
const routes = [
	// ib-China主页
	{
		path: '/',
		name: 'home',
		component: index,
		meta: {
			// 在路由中设置meta中需要的信息
			title: '首页_中国智能建筑信息网',
			keywords:
				'中国智能建筑信息网、建筑信息网、中国智能建筑前沿资讯、精彩视频、专家智库、品牌展会、专家资源、品牌专区、展会信息、大咖说、智库报告、精品期刊、建筑电气品牌、品牌竞争力、品牌十强、科技创新产品、优秀工作者、设计院渠道、青年专家、双高专家、项目信息、品牌信息、产品信息、优秀项目、媒体矩阵、'
		},
	},
	{
		path: '/news',
		name: 'news',
		component: news,
		meta: {
			title: '前沿资讯_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/videos',
		name: 'videos',
		component: videos,
    meta: {
			title: '精彩视频_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/VideoDetails',
		name: 'VideoDetails',
		component: VideoDetails,
    meta: {
			title: '_精彩视频_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/search',
		name: 'search',
		component: search,
	},
	{
		path: '/topic',
		name: 'topic',
		component: topic,
    meta: {
			title: '_活动专题_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/ResearchReport',
		name: 'ResearchReport',
		component: ResearchReport,
    meta: {
			title: '研究报告_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/ResearchDetail',
		name: 'ResearchDetail',
		component: ResearchDetail,
    meta: {
			title: '_研究报告详情_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/StarSaid',
		name: 'StarSaid',
		component: StarSaid,
    meta: {
			title: '大咖说_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/ExhibitInfo',
		name: 'ExhibitInfo',
		component: ExhibitInfo,
    meta: {
			title: '展会信息_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/ImageTextContent',
		name: 'ImageTextContent',
		component: ImageTextContent,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/notice',
		name: 'notice',
		component: notice,
    meta: {
			title: '通知公告_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	// ib-China分会
	{
		path: '/branch',
		name: 'branch',
		component: branch,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/aboutbranch',
		name: 'aboutbranch',
		redirect: '/aboutbranch/AboutView',
		component: aboutbranch,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
		children: [
			{
				path: 'AboutView',
				component: AboutView,
			},
			{
				path: 'WorkRegulations',
				component: WorkRegulations,
			},
			{
				path: 'MainJobView',
				component: MainJobView,
			},
			{
				path: 'OrganizationFrame',
				component: OrganizationFrame,
			},
		],
	},
	{
		path: '/member',
		name: 'member',
		component: member,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/achievement',
		name: 'achievement',
		component: achievement,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/apply',
		name: 'apply',
		component: apply,
		redirect: '/apply/applyfirst',
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
		children: [
			{
				path: 'applyfirst',
				component: applyfirst,
			},
			{
				path: 'applysecond',
				component: applysecond,
			},
			{
				path: 'certificates',
				component: certificates,
			},
			{
				path: 'CertificatesDownload',
				component: CertificatesDownload,
			},
			{
				path: 'change',
				component: change,
			},
			{
				path: 'notFound',
				component: notFound,
			},
			{
				path: 'Failed',
				component: Failed,
			},
		],
	},
	{
		path: '/inforevise',
		name: 'inforevise',
		component: inforevise,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/inforeviseSecond',
		name: 'inforeviseSecond',
		component: inforeviseSecond,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/BranchNotice',
		name: 'BranchNotice',
		component: BranchNotice,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/BranchNoticeDetail',
		name: 'BranchNoticeDetail',
		component: BranchNoticeDetail,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/BranchImageText',
		name: 'BranchImageText',
		component: BranchImageText,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/ItemEvaluation',
		name: 'ItemEvaluation',
		component: ItemEvaluation,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/ConfirmData',
		name: 'ConfirmData',
		component: ConfirmData,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/ConfirmDataSecond',
		name: 'ConfirmDataSecond',
		component: ConfirmDataSecond,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/ApplyFinish',
		name: 'ApplyFinish',
		component: ApplyFinish,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/ApplyFinishViewSecond',
		name: 'ApplyFinishViewSecond',
		component: ApplyFinishViewSecond,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网',
		},
	},
	{
		path: '/Contact',
		name: 'Contact',
		component: Contact,
    meta: {
			title: '_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	// bsan
	{
		path: '/bsan',
		name: 'bsan',
		component: bsan,
    meta: {
			title: '品牌_中国智能建筑信息网',
			keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/Brandfocus',
		name: 'Brandfocus',
		component: Brandfocus,
    meta: {
			title: '品牌聚焦_品牌_中国智能建筑信息网',
      keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/Review',
		name: 'Review',
		component: Review,
    meta: {
			title: '往届回顾_品牌_中国智能建筑信息网',
      keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/ContactUs',
		name: 'ContactUs',
		component: ContactUs,
    meta: {
			title: '联系我们_品牌_中国智能建筑信息网',
      keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/JudgingPanel',
		name: 'JudgingPanel',
		component: JudgingPanel,
    meta: {
			title: '评委会_品牌_中国智能建筑信息网',
      keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/organize',
		name: 'organize',
		component: organize,
		redirect: '/organize/Guidance',
    meta: {
			title: '组织机构_品牌_中国智能建筑信息网',
      keywords: '中国智能建筑信息网'
		},
		children: [
			{
				path: 'Guidance',
				component: Guidance,
			},
			{
				path: 'Sponsor',
				component: Sponsor,
			},
			{
				path: 'Support',
				component: Support,
			},
		],
	},
	{
		path: '/trends',
		name: 'trends',
		component: trends,
    meta: {
			title: '_中国智能建筑信息网',
      keywords: '中国智能建筑信息网'
		},
	},
	{
		path: '/Award',
		name: 'Award',
		component: Award,
		redirect: '/Award/topten',
    meta: {
			title: '奖项设置_品牌_中国智能建筑信息网',
      keywords: '中国智能建筑信息网'
		},
		children: [
			{
				path: 'topten',
				component: topten,
			},
			{
				path: 'newproduct',
				component: newproduct,
			},
			{
				path: 'worker',
				component: worker,
			},
		],
	},
	// {
	//     path: "/headline",
	//     name: 'headline',
	//     component: headline
	// },
	// {
	//     path: "/headlineDetail",
	//     name: 'headlineDetail',
	//     component: headlineDetail
	// },
	{
		path: '/ProductIntroduction',
		name: 'ProductIntroduction',
		component: ProductIntroduction
	},
	{
		path: '/PastReview',
		name: 'PastReview',
		component: PastReview
	},

	// 轮播公共
	{
		path: '/BannerFirst',
		name: 'BannerFirst',
		component: BannerFirst,
	},
	// // 添加重定向规则
	// {
	// 	path: '/:pathMatch(.*)*',
	// 	redirect: '/'
	// }
];
// const router = createRouter({
// 	history: createWebHistory(),
// 	routes,
// 	scrollBehavior(to, from, savedPosition) {
// 	  if (savedPosition) {
// 		return savedPosition;
// 	  } else {
// 		return { top: 0 };
// 	  }
// 	}
//   });
const router = createRouter({
	scrollBehavior: () => ({ y: 0 }),
	history: createWebHashHistory(),
	routes,
});
export default router;