import axios from "../util/request.js";
let {
    get,
    post
} = axios

export const getIbBranchesHomeList = data => post("/api/IBChina/IbBranchesHomeList", data)
// 通知公告、资讯、大咖说、研究报告
export const getIbPngContentHomeList = data => post("/api/IBChina/IbPngContentHomeList", data)
// 轮播
export const getBannerPngHomeList = data => post("/api/IBChina/BannerPngHomeList", data)
// 栏目
export const getSysColumnsHomeList = data => post("api/IBChina/sysColumnsHomeList", data)
// 精彩视频
export const getIbVideoContentHomeList = data => post("api/IBChina/IbVideoContentHomeList", data)
// 精彩视频 label 标签基础数据
export const sysLablesList = data => get("api/ApplyCompanyss/sysLablesList", data)
// 精彩视频 标签id查询
export const WebSiteGetVideoContentList = data => get("api/ApplyCompanyss/WebSiteGetVideoContentList", data)
// 前沿资讯轮播图
export const getIbPngContentFrontHomeList = data => post("api/IBChina/IbPngContentFrontHomeList", data)
//A01活动专题
export const getIbActivityHomeList = data => post("/api/IBCHina/IbActivityHomeList", data)
// 活动专题-嘉宾风采
export const getSysGuestsHomeList = data => post("/api/IBChina/sysGuestsHomeList?", data)
// 活动专题-主题发言
export const getSysKeynoteHomeList = data => post("/api/IBChina/sysKeynoteHomeList?", data)

// 分会通知 项目优品 科研出版 
export const getIbBranchPngHomeList = data => post("api/IBChina/ibBranchPngHomeList", data)
// 科研成果 图书 图集 标准 报告
export const getIbBranchPngHomeListDetail = data => post("api/IBChina/ibBranchPngHomeListDetail", data)

// 会员管理类别
export const getIbUserVipsHomeMemberPgaeList = data => post("api/IBChina/ibUserVipsHomeMemberPgaeList", data)
// 会员左侧分组
export const getSysGroupVipsHomeList = data => post("api/IBChina/sysGroupVipsHomeList", data)

// 会员管理 查询id为会员管理中的value字段
export const getIbUserVipsHomeList = data => post("api/IBChina/ibUserVipsHomeList", data)

// 新增入会申请
export const getIbJoinInfoAdd = data => post("api/IBChina/IbJoinInfoAdd", data)

// 友情链接
export const getBasiesLinksList = data => post("api/IBChina/basiesLinksList", data)


//导出电子证书
export const getIbJoinInfoSelectPdf = data => post("api/IBChina/IbJoinInfoSelectPdf", data)
// 关于我们 
export const getIbAboutUsList = data => post("api/IBChina/IbAboutUsList", data)
export const getBasiesAboutsList = data => post("api/IBChina/basiesAboutsList", data)

// 联系方式
export const getBasiesContactList = data => post("api/IBChina/basiesContactList", data)

// 二维码
export const getbasiesQRCodeList = data => post("api/IBChina/basiesQRCodeList", data)

// 媒体矩阵
export const getbasiesMediasList = data => post("api/IBChina/basiesMediasList", data)
// pdf导出
export const getexportPdf = data => post("api/IBChina/exportPdf", data)
// 活动专题查询 A01 id查询
export const getIbActivityHomeDetailList = data => post("api/IBChina/IbActivityHomeDetail", data)


// bsan
// 25个产品类别data
export const GetApplyProTypeSelectData = data => get("api/IBChina/GetApplyProTypeSelectData", data)
// 顶部三个类别行业品牌榜、优秀科技创新传品、设计优秀渠道工作者
export const GetGetApplyTypeSelectData = data => get("api/IBChina/GetGetApplyTypeSelectData", data)
// 中国工程勘察设计行业 建筑电气品牌竞争力十强数据
export const GetArticleData = data => get("api/IBChina/GetArticleData", data)
// 品牌聚焦-奖项类别
export const GetApplyJuJiaoData = data => get("api/IBChina/GetApplyJuJiaoData", data)
// 品牌聚焦-获取品牌聚焦年份数据
export const GetBrandFocusYearTypeData = data => get("api/IBChina/GetBrandFocusYearTypeData", data)
// 品牌聚焦-获取品牌聚焦数据
export const GetBrandFocusData = data => get("api/IBChina/GetBrandFocusData", data)
// 1.组织机构
export const WebSiteGetBrandFocusData = data => get("api/IBChina/WebSiteGetBrandFocusData", data)
// 2.服务展示
export const WebSiteGetFuWuZhanshiData = data => get("api/IBChina/WebSiteGetFuWuZhanshiData", data)
// 3.往届回顾
export const WebSiteGetWangjieHuiguData = data => get("api/IBChina/WebSiteGetWangjieHuiguData", data)
// 评委会
export const GetApplyCompanyData = data => get("api/IBChina/GetApplyCompanyData", data)
// 评委会类型数据
export const GetApplyCompanyTypeData = data => get("api/IBChina/GetApplyCompanyTypeData", data)
// 前端奖项数据接口
export const WebSiteGetAwardstableData = data => get("api/IBChina/WebSiteGetAwardstableData", data)
// 获取关于我们BSAN
export const GetUsBsan = data => get("api/ApplyCompanyss/GetUsBsan", data)

// 前端获取c板块轮播图默认为1 底部轮播为2
export const WebSiteGetCplatebannerData = data => get("api/CplateInformation/WebSiteGetCplatebannerData", data)
// 获取C板块首页文字信息
export const GetWebSiteScriptstatistic = data => get("api/CplateInformation/GetWebSiteScriptstatistic", data)

// 12个系统类别数据
export const getSystemList = data => get("api/IBChina/GetSystemList", data)
// 根据父级系统查询子集数据
export const getChildList = data => get("api/IBChina/GetChildList", data)