import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from '../../../node_modules/swiper/vue/swiper-vue.js';
import '../../../node_modules/swiper/swiper-bundle.css';
import PublicHeader from '@/components/PublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
// import BannerFourth from '@/components/banner/BannerFourth.vue'
import { getIbVideoContentHomeList } from '@/api/index.js';
import $ from '@/js/jquery.min.js';
export default {
  name: 'VideoDetails',
  components: {
    PublicHeader,
    PublicFooter,
    // BannerFourth,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      Id: '',
      list: [],
      idList: [],
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
      metatitle: ''
    };
  },
  mounted() {
    this.Id = this.$route.query.Id;
    this.getIbVideoContent();
    $('#videoplayer').bind('contextmenu', function () {
      return false;
    });
    this.getIbVideoContentById();
  },
  watch: {
    $route(to, from) {
      // console.log(to, from)
      this.getIbVideoContent();
    }
  },
  methods: {
    getIbVideoContentById() {
      getIbVideoContentHomeList({
        pageIndex: 1,
        pageSize: 50,
        Id: this.Id
      }).then(res => {
        this.idList = res.rows;
        this.metatitle = res.rows[0].VideoTitle;
      });
    },
    changeVideo(Id) {
      getIbVideoContentHomeList({
        pageIndex: 1,
        pageSize: 50,
        Id: Id
      }).then(res => {
        this.idList = res.rows;
      });
    },
    getIbVideoContent() {
      getIbVideoContentHomeList({
        pageIndex: 1,
        pageSize: 50
      }).then(res => {
        this.list = res.rows;
        // console.log(this.list)
      });
    }
  }
};