import BPublicHeader from '@/components/BPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import { getIbBranchPngHomeList } from '@/api/index';
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
export default {
  name: 'BranchNoticeDetail',
  components: {
    BPublicHeader,
    PublicFooter
  },
  data() {
    return {
      // 分会通知
      list: [],
      idList: [],
      BranName: '',
      Id: '',
      // CoverPngUrl: '',
      PlateName: '',
      metaname: ''
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined || this.BranName == 'undefined') {
      this.BranName = '中国勘察设计协会电气分会';
    }
    // console.log(this.BranName)
    this.Id = this.$route.query.Id;
    this.ArticleTitel = this.$route.query.ArticleTitel;
    this.CreateTime = this.$route.query.CreateTime;
    this.RichText = this.$route.query.RichText;
    // this.CoverPngUrl = this.$route.query.CoverPngUrl
    this.PlateName = this.$route.query.PlateName;
    // this.getIbBranchPng()
    this.getIbBranchPngById();
  },
  methods: {
    getIbBranchPngById() {
      getIbBranchPngHomeList({
        pageIndex: 1,
        pageSize: 50,
        Id: this.Id
      }).then(res => {
        this.idList = res.rows;
        this.metaname = res.rows[0].ArticleTitel;
        this.PlateName = res.rows[0].PlateName;
        // console.log(this.idList)
      });
    }
    // getIbBranchPng() {
    //   this.list = []
    //   getIbBranchPngHomeList({ pageIndex: 1, pageSize: 50, plateName: '分会通知', branchName: this.BranName }).then(res => {
    //     this.list = res.rows
    //     // console.log(this.list)
    //   })
    // }
  }
};