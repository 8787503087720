import BPublicHeader from '@/components/BPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import { getIbBranchPngHomeListDetail } from '@/api/index';
export default {
  name: 'achievement',
  components: {
    BPublicHeader,
    PublicFooter
  },
  data() {
    return {
      list: [{
        id: 1,
        name: '图书'
      }, {
        id: 2,
        name: '图集'
      }, {
        id: 3,
        name: '标准'
      }, {
        id: 4,
        name: '报告'
      }],
      activeNum: 0,
      // 科研成果
      listCG: [],
      BranName: '',
      page: {
        Index: 1,
        Size: 5,
        Total: 25
      }
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
    this.getIbBranchPngDetail('图书');
  },
  methods: {
    active(i) {
      this.activeNum = i;
      // console.log(this.activeNum)
      // console.log(this.list[this.activeNum])
      this.getIbBranchPngDetail(this.list[this.activeNum].name);
      // console.log(this.list[this.activeNum].name);
    },
    getIbBranchPngDetail(PlateName) {
      this.listCG = [];
      getIbBranchPngHomeListDetail({
        pageIndex: this.page.Index,
        pageSize: this.page.Size,
        PlateName: PlateName,
        branchName: this.BranName
      }).then(res => {
        this.listCG = res.rows;
        this.page.Total = res.total;
        // if(this.AchieveName==res.plateName && this.i==res.id){
        // console.log(this.listCG)
        // }
      });
    },
    handleCurrentChange(val) {
      this.page.Index = val;
      // console.log(`当前页: ${val}`)
      this.getIbBranchPngDetail();
    }
  }
};