import { getBasiesLinksList, getBasiesAboutsList, getBasiesContactList, getbasiesQRCodeList } from '@/api/index';
export default {
  name: 'PublicFooter',
  props: {},
  data() {
    return {
      list1: [],
      //友情链接
      list2: [],
      //关于我们
      listZhuGuan: [],
      HostCompany: [],
      list3: [],
      //联系放肆
      list4: [] //二维码
    };
  },
  mounted() {
    this.getBasiesLinks();
    this.getBasiesAbouts();
    this.getBasiesContact();
    this.getbasiesQRCode();
  },
  methods: {
    getBasiesLinks() {
      ;
      this.list1 = [], getBasiesLinksList({}).then(res => {
        this.list1 = res;
      });
    },
    getBasiesAbouts() {
      ;
      this.list2 = [], getBasiesAboutsList({}).then(res => {
        this.listZhuGuan = res[0].EDCompany.split(';');
        this.list2 = res[0].COCompany.split(';');
        this.HostCompany = res[0].HostCompany.split(';');
        // console.log(this.list2 + '关于我们')
        // console.log(this.HostCompany + '主办单位')
        // console.log(this.listZhuGuan + '主管单位')
      });
    },
    getBasiesContact() {
      ;
      this.list3 = [], getBasiesContactList({}).then(res => {
        this.list3 = res;
      });
    },
    getbasiesQRCode() {
      ;
      this.list4 = [], getbasiesQRCodeList({}).then(res => {
        this.list4 = res;
      });
    }
  }
};