import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7cac1a29"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "type-page"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("header", null, [_createElementVNode("div", {
    class: "c-header flex bg-header",
    style: _normalizeStyle({
      background: 'url(' + _ctx.hostUrl + '/images/20230506142223_9a357330-5fa0-4937-8d13-ffac1560d149.png)no-repeat center center'
    })
  }, null, 4), _createElementVNode("nav", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tablist, item => {
    return _openBlock(), _createElementBlock("li", {
      key: item.id
    }, [_createVNode(_component_router_link, {
      class: "color-1",
      to: item.path
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
      _: 2
    }, 1032, ["to"])]);
  }), 128))])])])]);
}