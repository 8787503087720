import PublicHeader from '@/components/PublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
import BannerFirst from '@/components/banner/BannerFirst.vue';
import indexSwiper from '@/components/indexSwiper.vue';
import { getIbPngContentHomeList } from '@/api/index.js';
export default {
  name: 'ImageTextContent',
  components: {
    PublicHeader,
    PublicFooter,
    BannerFirst,
    indexSwiper
  },
  data() {
    return {
      listZX: [],
      idList: [],
      Id: '',
      metatitle: '',
      PlateName: ''
    };
  },
  mounted() {
    this.Id = this.$route.query.Id;
    this.getIbPngContent();
    this.getIbPngContentById();
  },
  watch: {
    $route(to, from) {
      //监听到路由（参数）改变
      // 拿到目标参数 val.query.typeCode 去再次请求数据接口
      // console.log(this.Id)
      this.getIbPngContent();
    }
  },
  methods: {
    getIbPngContentById() {
      getIbPngContentHomeList({
        Id: this.Id,
        pageIndex: 1,
        pageSize: 50
      }).then(res => {
        this.idList = res.rows;
        this.metatitle = res.rows[0].ArticleTitel;
        this.PlateName = res.rows[0].PlateName;
        // console.log(this.idList)
      });
    },
    changeDetails(Id) {
      getIbPngContentHomeList({
        pageIndex: 1,
        pageSize: 50,
        Id: Id
      }).then(res => {
        this.idList = res.rows;
      });
    },
    getIbPngContent() {
      this.listZX = [];
      getIbPngContentHomeList({
        pageIndex: 1,
        pageSize: 50,
        PlateName: '前沿资讯'
      }).then(res => {
        this.listZX = res.rows;
        // console.log(this.listZX)
      });
    }
  }
};