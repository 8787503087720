import "core-js/modules/es.array.push.js";
import { getIbBranchesHomeList } from '@/api/index';
export default {
  name: 'AboutView',
  data() {
    return {
      // 分会简介
      list: [],
      BranName: ''
    };
  },
  mounted() {
    // 分会简介
    this.getIbBranches();
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined || this.BranName == 'undefined') {
      this.BranName = '中国勘察设计协会电气分会';
    }
  },
  methods: {
    // 分会简介
    getIbBranches() {
      this.list = [];
      getIbBranchesHomeList({
        BranName: this.BranName
      }).then(res => {
        this.list = res;
        // console.log(this.list);
        if (this.list.length > 0) {
          this.list.forEach(element => {
            // 分会简介
            // this.list=[]
            if (element.BranName == this.BranName) {
              this.list = [];
              this.list.push(element);
              // console.log(this.list)
            }
          });
        }
      });
    }
  }
};