import CPublicHeader from '@/components/CPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
export default {
  name: 'organize',
  components: {
    CPublicHeader,
    PublicFooter
  },
  data() {
    return {
      ltablist: [{
        id: 1,
        name: '指导单位',
        path: '/organize/Guidance'
      }, {
        id: 2,
        name: '主办单位',
        path: '/organize/Sponsor'
      }, {
        id: 3,
        name: '合作支持机构',
        path: '/organize/Support'
      }]
    };
  }
};