export default {
  name: 'applysecond',
  data() {
    return {
      list: [{
        id: 1,
        url: '/InfoRevise'
      }],
      BranName: ''
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
  }
};