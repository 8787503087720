import BPublicHeader from '@/components/BPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
export default {
  name: 'ApplyFinishViewSecond',
  components: {
    BPublicHeader,
    PublicFooter
  },
  data() {
    return {
      BranName: ''
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
  }
};