import BPublicHeader from '@/components/BPublicHeader.vue';
import PublicFooter from '@/components/PublicFooter.vue';
export default {
  name: 'aboutbranch',
  components: {
    BPublicHeader,
    PublicFooter
  },
  data() {
    return {
      list: [{
        id: 1,
        name: '分会简介',
        url: '/aboutbranch/AboutView'
      }, {
        id: 2,
        name: '工作条例',
        url: '/aboutbranch/WorkRegulations'
      }, {
        id: 3,
        name: '主要工作职能',
        url: '/aboutbranch/MainJobView'
      }, {
        id: 4,
        name: '组织框架',
        url: '/aboutbranch/OrganizationFrame'
      }],
      activeNum: 0,
      BranName: ''
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
  },
  methods: {
    active(i) {
      this.activeNum = i;
    }
  }
};