import "core-js/modules/es.array.push.js";
import { getIbJoinInfoSelectPdf } from '@/api/index.js';
import { ref } from 'vue';
export default {
  name: 'certificates',
  data() {
    return {
      list: [],
      DataInfo: {
        companyName: '',
        SDName: '',
        SDPhone: '',
        typeName: ''
      },
      Message: '',
      Result: '',
      typeName: [{
        value: '常务理事申请',
        label: '常务理事申请'
      }, {
        value: '理事申请',
        label: '理事申请'
      }],
      typeName2: [{
        value: '常务理事申请',
        label: '常务委员申请'
      }, {
        value: '常务理事申请',
        label: '委员申请'
      }],
      rules: {
        SDPhone: [{
          required: true,
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: '请输入正确的手机号',
          trigger: 'blur'
        }]
      },
      BranName: ''
    };
  },
  mounted() {
    this.BranName = this.$route.query.BranName;
    if (this.BranName == '' || this.BranName == undefined) {
      this.BranName = '中国勘察设计协会电气分会';
    }
  },
  methods: {
    submitForm() {
      this.$refs['infoForm'].validate(valid => {
        if (valid) {
          getIbJoinInfoSelectPdf(this.DataInfo).then(res => {
            if (res.Message == '已通过') {
              // this.$router.push('/apply/CertificatesDownload?herf=' + res.Result)
              this.$router.push({
                path: '/apply/CertificatesDownload',
                query: {
                  herf: res.Result,
                  BranName: this.BranName
                }
              });
            } else if (res.Message == '错误，没有查到可下载的数据') {
              this.$router.push({
                path: '/apply/Failed',
                query: {
                  BranName: this.BranName
                }
              });
            } else if (res.Message == '未审核') {
              this.$router.push({
                path: '/apply/notFound',
                query: {
                  BranName: this.BranName
                }
              });
            }
          });
        }
      });
    }
  }
};